<template>
  <div>
    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>
    <div v-else>
      <div class="d-flex justify-content-center min-h-100px" v-if="isLoading">
        <div class="spinner spinner-dark"></div>
      </div>
      <div class="row" v-else>
        <div class="col-lg-9">
          <div class="card card-custom card-border card-shadowless">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-lg-3">
                  <CalendarFilter 
                    :filter-options="filters.options" 
                    :filter-initial-status="filters.initalStatus"
                    :initial-date="initialDate"
                    @filterChanged="filterChanged"
                    @dateChanged="dateChanged"
                  />
                </div>
                <div class="col-lg-9">
                  <div class="pt-5">
                    <Calendar 
                      ref="calendar"
                      :experience-id="experienceId" 
                      :filter="selectedFilterOptions" 
                      :date="selectedDate" 
                      :theme="experience.theme"
                      :service-type="experience.serviceType"
                      @dateChanged="calendarViewChanged"
                      @eventSelected="calendarEventSelected"
                      @date-selected="calendarDateSelected"
                    />
                  </div>
                </div>
              </div>
            </div>    
          </div>
        </div>
        <div class="col-lg-3">
          <EventContent 
            :experience-id="experienceId" 
            :pnr="selectedEventPnr" 
            :service-type="experience.serviceType"
            :event-status="selectedEventStatus"
            :theme="experience.theme"
            @event-status-updated="eventStatusUpdated"
            @copy-event="copyEvent"
            v-if="selectedEventPnr != ''"
          />

          <EventActions 
            :experience-id="experienceId" 
            :start="selectedCalendarDates.start"
            :end="selectedCalendarDates.end"
            v-if="selectedCalendarDates.status"
            @action-submitted="$refs.calendar.getEvents()"
          />          
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import CalendarFilter from "@/view/pages/experience/components/calendar/Filter";
import Calendar from "@/view/pages/experience/components/calendar/Calendar";
import EventContent from "@/view/pages/experience/components/calendar/EventContent";
import EventActions from "@/view/pages/experience/components/calendar/EventActions";

export default {
  props: ["experienceId"],
  components: {
    CalendarFilter,
    Calendar,
    EventContent,
    EventActions
  },
  data() {
    return {
      experience: {
        theme: null,
        status: null,
        serviceType: null,
        name: {
          tr: '',
          en: ''
        }
      },
      error: {
        status: false,
        message: 'Beklenmedik bir hatayla karşılaştık. Lütfen daha sonra tekrar deneyin.'
      },
      filters: {
        options: [
          {
            id: "soldOut",
            title: "Tükendi",
            value: 2,
            state: "danger",
          },
          {
            id: "available",
            title: "Müsait",
            value: 1,
            state: "success",
          },
          {
            id: "noSale",
            title: "Satışa Kapalı",
            value: 3,
            state: "dark",
          }
        ],
        initalStatus: [1, 2, 3]
      },
      initialDate: moment().format('YYYY-MM-DD'),
      selectedFilterOptions: [],
      selectedDate: moment().format('YYYY-MM-DD'),
      selectedEventPnr: '',
      selectedEventStatus: null,
      isLoading: true,
      selectedCalendarDates: {
        status: false,
        start: '',
        end: ''
      }
    }
  },
  mounted() {
    this.getExperience();
  },
  methods: {
    getExperience() {
      ApiService.get(`experience/calendar/experience/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.experience.theme = data.experience.theme;
        this.experience.serviceType = data.experience.serviceType;
        this.experience.status = data.experience.status;
        this.experience.name = data.experience.name;
        this.error.status = false;
        setTimeout(() => {
          this.isLoading = false;  
        }, 250);
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
        setTimeout(() => {
          this.isLoading = false;  
        }, 250);
      });
    },
    filterChanged(val) {
      this.selectedFilterOptions = val;
    },
    dateChanged(val) {
      this.selectedDate = val;
    },
    calendarViewChanged(val) {
      this.initialDate = val;
      this.selectedEventPnr = '';
      this.selectedEventStatus = null;

      this.clearSelectedCalendarDates();
    },
    calendarDateSelected(start, end) {
      this.selectedCalendarDates.status = true;
      this.selectedCalendarDates.start = start;
      this.selectedCalendarDates.end = end;
      
      this.selectedEventPnr = '';
      this.selectedEventStatus = null;
    },
    calendarEventSelected(pnr, status) {
      this.clearSelectedCalendarDates();
      this.selectedEventPnr = pnr;
      this.selectedEventStatus = status;
    },
    eventStatusUpdated() {
      this.selectedEventPnr = '';
      this.selectedEventStatus = null;
      this.$refs.calendar.getEvents();
    },
    copyEvent(pnr, start, end) {
      this.selectedEventPnr ='';
      this.selectedEventStatus = null;
      this.$refs.calendar.copyEvent(pnr, start, end);
    },
    clearSelectedCalendarDates() {
      this.selectedCalendarDates.status = false;
      this.selectedCalendarDates.start = '';
      this.selectedCalendarDates.end = '';
    }
  }
}
</script>