<template>
  <div>
    <div class="card card-custom card-fit">
      <div class="card-body">
        <p class="font-size-h3 font-weight-bold mb-8">{{ selectedCalendarDatesText }}</p>
        <div class="d-flex flex-column flex-fill" >
          <div class="d-flex flex-row mb-5">
            <button class="btn btn-light btn-lg font-weight-bold mr-2 flex-fill" @click="offSale"><i class="flaticon2-lock"></i>Satışa Kapat</button>
            <button class="btn btn-light btn-lg font-weight-bold ml-2 flex-fill" @click="onSale"><i class="flaticon2-calendar-5"></i>Satışa Aç</button>
          </div>
          <button class="btn btn-light btn-lg text-danger font-weight-bold" @click="remove"><i class="flaticon2-trash text-danger"></i>Müsaitlikleri Sil</button>
        </div>
      </div>
    </div>

    <!--Begin: CONFIRMATION MODAL-->
    <b-modal id="modal-confirmation" ref="modal-confirmation" no-fade centered header-class="py-3" footer-class="py-3" @ok="submitConfirmationModal">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>{{ confirmationModal.title }}</h5>
          </div>
        </div>
      </template>

      <p class="font-size-h6">
        {{ confirmationModal.message }}
      </p>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="isSubmitting"
            @click="ok"
          >
            <div class="d-flex align-items-center">
              <div class="spinner spinner-white mr-8" v-if="isSubmitting"></div>
              {{ confirmationModal.submitActionTitle }}
            </div>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: CONFIRMATION MODAL-->
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import moment from 'moment';

export default {
  props: ["experienceId", "start", "end"],
  data() {
    return {
      confirmationModal: {
        title: '',
        message: '',
        submitActionTitle: '',
        submitAction: null
      },
      isSubmitting: false
    }
  },
  computed: {
    selectedCalendarDatesText() {
      const startMY = moment(this.start, 'YYYY-MM-DD').format('MMM YYYY');
      const startD = moment(this.start, 'YYYY-MM-DD').format('D');
      const endMY = moment(this.end, 'YYYY-MM-DD').format('MMM YYYY');
      const endD = moment(this.end, 'YYYY-MM-DD').format('D');

      if (startMY === endMY) {
        if (startD === endD) {
          return `${moment(this.start, 'YYYY-MM-DD').format('D MMMM')}`;
        } else {
          return `${startD} - ${moment(this.end, 'YYYY-MM-DD').format('D MMMM')}`;
        }
      } else {
        return `${moment(this.start, 'YYYY-MM-DD').format('D MMM')} - ${moment(this.end, 'YYYY-MM-DD').format('D MMM')}`;
      }
    }
  },
  methods: {
    onSale() {
      this.confirmationModal.title = "Emin misin?"
      this.confirmationModal.message = this.selectedCalendarDatesText + ' tarihleri arasındaki müsaitlikleri satışa açmak istediğinize emin misiniz?';
      this.confirmationModal.submitActionTitle = "Evet, satışa aç!"
      this.confirmationModal.submitAction = 'onsale-event';
      this.$bvModal.show('modal-confirmation');
    },

    offSale() {
      this.confirmationModal.title = "Emin misin?"
      this.confirmationModal.message = this.selectedCalendarDatesText + ' tarihleri arasındaki müsaitlikleri satışa kapatmak istediğinize emin misiniz?';
      this.confirmationModal.submitActionTitle = "Evet, satışa kapat!"
      this.confirmationModal.submitAction = 'offsale-event';
      this.$bvModal.show('modal-confirmation');
    },

    remove() {
      this.confirmationModal.title = "Emin misin?"
      this.confirmationModal.message = this.selectedCalendarDatesText + ' tarihleri arasındaki müsaitlikleri silmek istediğinize emin misiniz? Bu işlem geri alınamaz!';
      this.confirmationModal.submitActionTitle = "Evet, sil!"
      this.confirmationModal.submitAction = 'remove-event';
      this.$bvModal.show('modal-confirmation');
    },

    submitConfirmationModal(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.submit();
    },
    
    submit() {
      this.isSubmitting = true;
      const postData = {
        id: this.experienceId,
        start: this.start,
        end: this.end
      }

      ApiService.post(`experience/calendar/${this.confirmationModal.submitAction}.req.php`, postData)
      .then(() => {
        this.$emit('action-submitted');
        setTimeout(() => {
          this.isSubmitting = false;
          this.$bvModal.hide('modal-confirmation');
        }, 500);
      })
      .catch(({ response }) => {
        setTimeout(() => {
          this.isSubmitting = false;
          this.$bvModal.hide('modal-confirmation')
          alert(response.data.errorMessage);
        }, 500);
      });
    }   
  }
}
</script>