<template>
  <div>
    <FullCalendar
      ref="calendar"
      :content-height="800"
      :editable="true" 
      :locale="locale" 
      :header="header" 
      :eventLimit="true" 
      :displayEventTime="false"
      :selectable="true"
      :unselect-auto="false"
      defaultView="dayGridMonth" 
      theme-system='bootstrap' 
      :button-icons="icons"
      :plugins="calendarPlugins" 
      :events="filteredEvents" 
      :viewSkeletonRender="initCalendar"
      :datesRender="dateChanged"
      :customButtons="customButtons"
      @eventClick="eventClicked"
      @eventDrop="eventDropped"
      @select="daySelected"
      />

      <!--Begin: CALENDAR-->
      <b-modal id="modal-create-record" ref="modal-create-record" size="400" no-fade centered header-class="py-3" footer-class="py-3" @ok="handleModalOk">
        <template #modal-header="{ cancel }">
          <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
          <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
            <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
              <h5>Etkinlik tarihi girin?</h5>
            </div>
          </div>
        </template>

        <div class="pl-5">
          <DateRangePicker :initial-date="{ start: datePickerModal.initialDate.start, end: datePickerModal.initialDate.end }" :min-date="datePickerModal.initialDate.start" @change="datePickerChanged"/>
        </div>

        <template #modal-footer="{ ok, cancel }">
          <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
            <button 
              type="button" 
              class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
              @click="cancel"
            >İptal et</button>

            <button 
              type="button" 
              class="btn btn-dark font-weight-bolder px-5 py-4" 
              @click="ok"
            >
              Bu tarihe kayıt oluştur
            </button>
          </div>
        </template>

      </b-modal>
      <!--End: CALENDAR-->
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import trLocale from '@fullcalendar/core/locales/tr';
import DateRangePicker from "@/view/content/datepicker/InlineDateRangePicker";

export default {
  props: ["experienceId", "filter", "date", "serviceType", "theme"],
  components: {
    FullCalendar,
    DateRangePicker
  },
  data() {
    return {
      calendarApi: null,
      header: {
        left: 'title',
        center: '',
        right: 'createRecord,prev,next'
      },
      customButtons: {
        createRecord: {
          text: 'Yeni Kayıt',
          click: () => this.openModal(),
        }
      },
      icons: {
        close: 'fa-times',
        prev: 'far fa-arrow-left',
        next: 'far fa-arrow-right',
        prevYear: 'fa-angle-double-left',
        nextYear: 'fa-angle-double-right'
      },
      locale: trLocale,
      calendarPlugins: [ dayGridPlugin, interactionPlugin ],
      events: [],
      filteredEvents: [],
      eventStyle: {
        0: 'fc-event-secondary fc-event-solid-white',
        1: {
          title: 'Müsait',
          class: 'fc-event-white fc-event-solid-success'
        },
        2: {
          title: 'Tükendi',
          class: 'fc-event-primary fc-event-solid-light'
        },
        3: {
          title: 'Satışa Kapalı',
          class: 'fc-event-lock fc-event-solid-light'
        }    
      },
      selectedEvent: null,
      copyType: {
        1: {
          1: 'daily-group',
          2: 'daily-private'
        },
        2: {
          1: 'accommodation-group',
          2: 'accommodation-private'
        },
        3: {
          1: 'accommodation-group',
          2: 'accommodation-private'
        },
        4: {
          1: 'daily-group',
          2: 'daily-private'
        },
        5: {
          1: 'daily-group',
          2: 'daily-private'
        },
        6: {
          1: 'daily-group',
          2: 'daily-private'
        },
      },
      datePickerModal: {
        initialDate: {
          start: moment().format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD')
        },
        start: '',
        end: ''
      } 
    }
  },
  watch: {
    filter() {
      this.filterEvents();
    },
    date(val) {
      this.calendarApi.gotoDate(val);
    }
  },
  mounted() {
    moment.locale('tr');
  },
  methods: {
    getEvents() {
      const from = moment(this.calendarApi.view.activeStart).format('YYYY-MM-DD');
      const to = moment(this.calendarApi.view.activeEnd).subtract(1, 'day').format('YYYY-MM-DD');

      ApiService.get(`experience/calendar/get.req.php?id=${this.experienceId}&from=${from}&to=${to}`)
      .then(({ data }) => {
        this.events = [];
        data.events.forEach(el => {
          let event = {
            start: el.from + 'T00:00:00',
            end: el.to + 'T00:00:01',
            id: el.pnr
          };

          if (moment(el.to).isBefore(moment().format('YYYY-MM-DD'))) {
            event.title = el.pnr;
            event.className = this.eventStyle.invlid;
            event.status = 0;
          } else {
            event.title = this.eventStyle[el.status].title;
            event.className = this.eventStyle[el.status].class
            event.status = el.status;
          }
          this.events.push(event);
          this.filterEvents();
        });
      })
      .catch(() => {
        this.events = [];
        this.filterEvents = [];
      });
    },
    copyEvent(pnr, start, end) {;
      const postData = {
        pnr,
        start,
        end,
        type: this.copyType[this.theme][this.serviceType]
      }
      ApiService.post('experience/calendar/event/copy/copy.req.php', postData)
      .then(() => {
        this.selectedEvent = null;
        this.getEvents();
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },
    initCalendar() {
      this.calendarApi = this.$refs.calendar.getApi();
    },
    filterEvents() {
      const filteredEvents = this.events.filter((el) => {
        return el.status === 0 || this.filter.includes(el.status);
      });
      this.filteredEvents = filteredEvents;
    },
    dateChanged() {
      const date = this.calendarApi.getDate();
      this.getEvents();
      this.$emit('dateChanged', moment(date).format('YYYY-MM-DD'));
    },
    eventClicked(info) {
      if (this.selectedEvent !== null) {
        setTimeout(() => {
          this.selectedEvent.classList.remove('fc-selected-event');
        }, 1);
      }
      setTimeout(() => {
        this.selectedEvent = info.el;
        this.selectedEvent.classList.add('fc-selected-event');  
      }, 2);
      
      const pnr = info.event.id;
      const status = info.event.extendedProps.status;
      this.calendarApi.unselect();
      this.$emit('eventSelected', pnr, status);
    },
    eventDropped(info) {
      this.$emit('eventSelected', '', null);
      this.copyEvent(info.event.id, moment(info.event.start).format("YYYY-MM-DD"), moment(info.event.end).format("YYYY-MM-DD"));
    },
    daySelected(info) {
      if (this.selectedEvent !== null) {
        setTimeout(() => {
          this.selectedEvent.classList.remove('fc-selected-event');
        }, 1);
      }
      const start = info.startStr;
      const end = moment(info.endStr, 'YYYY-MM-DD').subtract(1, 'day').format('YYYY-MM-DD');
      this.$emit('date-selected', start, end);
    },

    datePickerChanged(dateRange) {
      this.datePickerModal.start = dateRange.start;
      this.datePickerModal.end = dateRange.end;
    },
    handleModalOk() {
      const postData = {
        id: this.experienceId,
        start: this.datePickerModal.start,
        end: this.datePickerModal.end
      }
      ApiService.post('experience/calendar/create.req.php', postData)
      .then(() => {
        this.getEvents();
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },
    openModal() {
      this.$bvModal.show('modal-create-record');
    }
  }
}
</script>

<style lang='scss'>

@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

.fc-unthemed .fc-event-lock .fc-content:before {
  font-family: "Font Awesome 5 Free" !important;
  font-weight: 900;
  background: transparent !important;
  color: var(--dark);
  top: .5rem !important; 
  content: "\f023" !important;
}
.fc-unthemed .fc-toolbar .fc-button:first-child {
  margin-right: 1rem;  
}
.fc-unthemed .fc-toolbar .fc-button {
  border-radius: .85rem;
  height: calc(1.5em + 1.3rem + 2px);
  width: calc(1.5em + 1.3rem + 2px);
  padding: 0;
  background: var(--light);  
  margin-left: 10px;
}
.fc-unthemed .fc-toolbar .fc-button:hover {
  background: var(--secondary);  
}
.fc-unthemed .fc-toolbar .fc-button:active,.fc-unthemed .fc-toolbar .fc-button:focus {
  background: var(--secondary) !important;
  border-color: var(--dark) !important;
}
.fc-unthemed .fc-toolbar .fc-button .fa-arrow-left:before,.fc-unthemed .fc-toolbar .fc-button .fa-arrow-right:before {
  font-family: "Font Awesome 5 Free" !important;
  font-weight: 900;
  color: var(--dark) !important;
}
.fc-unthemed .fc-toolbar h2 {
  color: var(--dark) !important;
  font-size: 1.75rem !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.fc-unthemed th, 
.fc-unthemed td, 
.fc-unthemed thead, 
.fc-unthemed tbody, 
.fc-unthemed .fc-divider, 
.fc-unthemed .fc-row, 
.fc-unthemed .fc-content, 
.fc-unthemed .fc-popover, 
.fc-unthemed .fc-list-view, 
.fc-unthemed .fc-list-heading td {
  border-color: #EBEDF3 !important;
}
.fc-toolbar.fc-header-toolbar {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}
.fc-unthemed .fc-event.fc-selected-event {
  background: var(--info) !important;
} 
.fc-unthemed .fc-event.fc-selected-event .fc-title {
  color: var(--white) !important;
}
.fc-createRecord-button {
  border-radius: 0.85rem !important;
  height: calc(1.5em + 1.3rem + 2px) !important;
  width: auto !important;
  padding: 0 !important;
  background: var(--dark) !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  color: var(--white) !important;
  border: none !important;
  font-weight: 500 !important;
}
.fc-unthemed .fc-toolbar .fc-createRecord-button:hover {
  background: var(--dark) !important;
}
.fc-unthemed .fc-toolbar .fc-createRecord-button:focus,
.fc-unthemed .fc-toolbar .fc-createRecord-button:active {
  background: var(--dark) !important;
  color: var(--white) !important;
}
.fc-unthemed .fc-head-container.fc-widget-header {
  border-right: none !important;
}
.fc-unthemed .fc-widget-content {
  border-right: none !important;
  border-bottom: none !important;
}
div.modal .modal-dialog.modal-400 {
  width: 400px !important;
}
</style>