<template>
  <div>
    <div class="card card-custom my-5">
      <div class="card-body">
        <label class="font-weight-bolder text-dark">{{ componentHeaderText }} <span class="text-primary" v-if="expenses.length > 0">{{ totalExpenses }}</span></label>
        <div v-if="!error.status">
          <div class="d-flex justify-content-center min-h-100px" v-if="isLoading">
            <div class="spinner spinner-dark"></div>
          </div>
          <div v-else>
            <div class="d-flex align-items-center my-5 bg-white bg-hover-light border rounded px-5 py-3" v-for="(item, index) in expenses" :key="item.id" @click="openEditModal(index)">
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <a href="javascript:;" class="font-weight-bold text-dark-75 font-size-md mb-1">{{ item.name }}</a>
              </div>
              <span class="font-weight-bolder text-dark py-1 font-size-md">{{ item.price }} TRY</span>
            </div>
            <button class="btn btn-sm btn-light font-weight-bold" @click="$bvModal.show('modal-add-fixed-expense')" v-if="!error.status">Harcama ekle</button>
          </div>

        </div>
        
        <div v-else>
          <p> {{ error.message }} </p>
        </div>
      </div>
    </div>

    <!--Begin: ADD EXPENSE-->
    <b-modal id="modal-add-fixed-expense" ref="modal-add-fixed-expense" no-fade centered header-class="py-3" footer-class="py-3" @cancel="handleAddModalCancel" @hide="handleAddModalCancel" @ok="handleAddModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
              <label>{{ addModal.activeStep }} ADIM/{{addModal.steps.length}}</label>
            <h5>{{ addModal.steps[addModal.activeStep - 1].title }}</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div v-if="addModal.activeStep === 1">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Harcama adı</label>
            <input type="text" class="form-control form-control-lg" v-model="addModal.expenseName">
          </div>
        </div>
        
        <div v-if="addModal.activeStep === 2">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Harcama tutarı</label>
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text">TRY</span></div>
              <input type="number" class="form-control form-control-lg" v-model="addModal.expensePrice">
              <div class="input-group-append"><span class="input-group-text">.00</span></div>
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
            v-if="addModal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="addModal.activeStep = addModal.activeStep - 1" 
            v-if="addModal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>
          
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="addModal.expensePrice.length === 0 || addModal.expensePrice < 0"
            v-if="addModal.activeStep == addModal.steps.length"
          >
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="addModal.expenseName.length === 0"
            @click="addModal.activeStep = addModal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: ADD EXPENSE-->

    <!--Begin: EDIT EXPENSE-->
    <b-modal id="modal-edit-fixed-expense" ref="modal-edit-fixed-expense" no-fade centered header-class="py-3" footer-class="py-3" @cancel="handleEditModalCancel" @hide="handleEditModalCancel" @ok="handleEditModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ editModal.activeStep }} ADIM/{{editModal.steps.length}}</label>
            <h5>{{ editModal.steps[editModal.activeStep - 1].title }}</h5>
          </div>
        </div>
        <button class="btn btn-sm btn-icon btn-light-danger" @click="removeExpense">
          <i class="flaticon2-trash icon-md"></i>
        </button>
      </template>
      
      <div class="max-h-300px">
        <div v-if="editModal.activeStep === 1">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Harcama adı</label>
            <input type="text" class="form-control form-control-lg" v-model="editModal.expenseName">
          </div>
        </div>
        
        <div v-if="editModal.activeStep === 2">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Harcama tutarı</label>
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text">TRY</span></div>
              <input type="number" class="form-control form-control-lg" v-model="editModal.expensePrice">
              <div class="input-group-append"><span class="input-group-text">.00</span></div>
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
            v-if="editModal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="editModal.activeStep = editModal.activeStep - 1" 
            v-if="editModal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>
          
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="editModal.expensePrice.length === 0 || editModal.expensePrice < 0"
            v-if="editModal.activeStep == editModal.steps.length"
          >
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="editModal.expenseName.length === 0"
            @click="editModal.activeStep = editModal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: ADD EXPENSE-->

  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  props: ["pnr"],
  data() {
    return {
      expenses: [],
      serviceType: null,
      isLoading: true,
      error: {
        status: false,
        message: ''
      },
      addModal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Harcama adını girin'
          },
          {
            number: 2,
            title: 'Harcama tutarını girin'
          }
        ],
        expenseName: '',
        expensePrice: 0
      },

      editModal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Harcama adını düzenle'
          },
          {
            number: 2,
            title: 'Harcama tutarını düzenle'
          }
        ],
        expenseIndex: null,
        expenseId: null,
        expenseName: '',
        expensePrice: 0
      },
    }
  },
  mounted() {
    this.get();
  },
  computed: {
    componentHeaderText() {
      if (this.expenses.length === 0) {
        return 'Sabit Giderler';
      } else {
        return 'Toplam Satış Fiyatı: ';
      }
    },
    totalExpenses() {
      return this.expenses.reduce((a, {price}) => a + parseInt(price), 0) + ' TRY';
    }
  },
  watch: {
    pnr() {
      this.get();
    }
  },
  methods: {
    get() {
      ApiService.get(`experience/calendar/event/fixed-expenses/get.req.php?pnr=${this.pnr}`)
      .then(({ data }) => {
        this.expenses = data.expenses;
        this.serviceType = data.serviceType;
        this.error.status = false;
        setTimeout(() => {
          this.isLoading = false;  
        }, 250);
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
        setTimeout(() => {
          this.isLoading = false;  
        }, 250);
      });
    },

    handleAddModalCancel() {
      this.addModal.activeStep = 1;
      this.addModal.expenseName = '';
      this.addModal.expensePrice = 0;
    },
    handleAddModalOk() {
      const name = this.addModal.expenseName;
      const price = this.addModal.expensePrice;

      const postData = {
        name,
        price,
        pnr: this.pnr
      }

      ApiService.post('experience/calendar/event/fixed-expenses/add.req.php', postData)
      .then(({ data }) => {
        const newExpense = {
          id: data.expenseId,
          name,
          price
        }
        this.expenses.push(newExpense);
        this.$emit('notify', 'success', 'Başarılı! Harcama satış fiyatına eklendi.');
      })
      .catch(({ response }) => {
        this.$emit('notify', 'danger', response.data.errorMessage);
      });
    },

    openEditModal(index) {
      const fixedExpense = Object.assign({}, this.expenses[index]);
      this.editModal.expenseIndex = index;
      this.editModal.expenseId = fixedExpense.id;
      this.editModal.expenseName = fixedExpense.name;
      this.editModal.expensePrice = fixedExpense.price;
      this.$bvModal.show('modal-edit-fixed-expense');
    },
    handleEditModalCancel() {
      this.editModal.activeStep = 1;
      this.editModal.expenseIndex = null;
      this.editModal.expenseId = null;
      this.editModal.expenseName = '';
      this.editModal.expensePrice = 0;
    },
    handleEditModalOk() {
      const index = this.editModal.expenseIndex;
      const id = this.editModal.expenseId;
      const name = this.editModal.expenseName;
      const price = this.editModal.expensePrice;

      const postData = {
        id,
        name,
        price,
        pnr: this.pnr
      }

      ApiService.post('experience/calendar/event/fixed-expenses/set.req.php', postData)
      .then(() => {
        this.expenses[index].name = name;
        this.expenses[index].price = price;
        this.$emit('notify', 'success', 'Başarılı! Değişiklikler kaydedildi.');
      })
      .catch(({ response }) => {
        this.$emit('notify', 'danger', response.data.errorMessage);
      });
    },

    removeExpense() {
      const index = this.editModal.expenseIndex;
      const id = this.editModal.expenseId;

      const postData = {
        id,
        pnr: this.pnr
      }

      ApiService.post('experience/calendar/event/fixed-expenses/remove.req.php', postData)
      .then(() => {
        this.expenses.splice(index, 1);
        this.$bvModal.hide('modal-edit-fixed-expense');
        this.$emit('notify', 'success', 'Başarılı! Değişiklikler kaydedildi.');
      })
      .catch(({ response }) => {
        this.$emit('notify', 'danger', response.data.errorMessage);
      });
    }
  }
}
</script>

<style>

</style>