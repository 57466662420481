<template>
  <div>
    <div class="card card-custom my-5">
      <div class="card-body">
        <div class="d-flex flex-row flex-wrap justify-content-between">
          <label class="font-weight-bolder text-dark">Doluluk</label>
          <button class="btn btn-sm btn-light font-weight-bold" v-if="!error.status" @click="openModal">Düzenle</button>
        </div>

        <div v-if="!error.status">
          <div class="font-weight-bold text-muted font-size-sm">
          <span class="text-dark-75 font-size-h2 font-weight-bolder mr-2">{{ soldSeat }} Pax</span> / {{ quota }} pax</div>
          <div class="progress progress-xs mt-7" :class="progressBarState[eventStatus].secondary">
            <div class="progress-bar" :class="progressBarState[eventStatus].primary" role="progressbar" :style="`width: ${progressBarWidth}%`" :aria-valuenow="progressBarWidth" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>

        <div v-else>
          <p> {{ error.message }} </p>
        </div>

      </div>
    </div>

    <!--Begin: SET QUOTA-->
    <b-modal id="modal-set-quota" ref="modal-set-quota" no-fade centered header-class="py-3" footer-class="py-3" @cancel="handleModalCancel" @hide="handleModalCancel" @ok="handleModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>Kotayı düzenle</h5>
          </div>
        </div>
      </template>
      
      <div class="form-group">
        <input type="number" class="form-control form-control-lg" v-model="modal.quota">
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="isModalSaveBtnDisabled"
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: SET QUOTA-->

  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  props: ["pnr"],
  data() {
    return {  
      quota: 0,
      soldSeat: 0,
      eventStatus: null,
      serviceType: null,
      error: {
        status: false,
        message: ''
      },
      isLoading: true,
      progressBarState: {
        1: {
          primary: 'bg-success',
          secondary: 'bg-success-o-60'
        },
        2: {
          primary: 'bg-danger',
          secondary: 'bg-danger-o-60'
        },
        3: {
          primary: 'bg-secondary',
          secondary: 'bg-secondary-o-60'
        } 
      },

       modal: {
        quota: 0
      },
    }
  },
  mounted() {
    this.get();
  },
  computed: {
    progressBarWidth() {
      return this.soldSeat * 100 / this.quota;
    },
    isModalSaveBtnDisabled() {
      if (this.modal.quota < this.soldSeat || this.modal.quota.length === 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    pnr() {
      this.get();
    }
  },
  methods: {
    get() {
      ApiService.get(`experience/calendar/event/solidity-ratio/get.req.php?pnr=${this.pnr}`)
      .then(({ data }) => {
        this.quota = data.quota;
        this.soldSeat = data.soldSeat;
        this.eventStatus = data.eventStatus;
        this.serviceType = data.serviceType;
        this.error.status = false;
        setTimeout(() => {
          this.isLoading = false;  
        }, 250);
        this.$emit('soldseat', this.soldSeat);
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
        setTimeout(() => {
          this.isLoading = false;  
        }, 250);
        this.$emit('soldseat', null);
      });
    },

    openModal() {
      this.modal.quota = this.quota;
      this.$bvModal.show('modal-set-quota');
    },
    handleModalCancel() {
      this.modal.quota = 0;
    },
    handleModalOk() {
      const newQuota = this.modal.quota;
      const postData = {
        pnr: this.pnr,
        quota: newQuota
      }

      ApiService.post('experience/calendar/event/solidity-ratio/set.req.php', postData)
      .then(() => {
        this.quota = newQuota;
        if (this.eventStatus != 3) {
          if (newQuota == this.soldSeat) {
            this.eventStatus = 2;
          } else {
            this.eventStatus = 1;
          }
        }
        this.$emit('notify', 'success', 'Başarılı! Etkinlik kotası güncellendi.');
      })
      .catch(({ response }) => {
        this.$emit('notify', 'danger', response.data.errorMessage);
      });
    }
  }
}
</script>

<style>

</style>