<template>
  <div>
    <div class="card card-custom my-5">
      <div class="card-body">
        <label class="font-weight-bolder text-dark">Hareket Saati</label>
        <div v-if="!error.status">
          <div class="d-flex justify-content-center min-h-100px" v-if="isLoading">
            <div class="spinner spinner-dark"></div>
          </div>
          <div class="d-flex flex-row flex-wrap mt-2" v-else>
            <button class="btn btn-sm btn-outline-dark font-weight-bold mr-3 mb-3" @click="openEditDepartureTimeModal(index)" v-for="(item, index) in departureTimes" :key="item.id">
              {{ momentApi(item.start, 'HH:mm:ss').format('HH:mm') }}<span v-if="theme === 6"> - {{ momentApi(item.end, 'HH:mm:ss').format('HH:mm') }} </span>
            </button>
            <button class="btn btn-sm btn-light font-weight-bold px-4 mr-3 mb-3" v-if="addButton" @click="$bvModal.show('modal-add-departure-time')">Ekle</button>
          </div>
        </div>
        <div v-else>
          <p> {{ error.message }} </p>
        </div>
      </div>
    </div>

    <!--Begin: ADD DEPARTURE TIME-->
    <b-modal id="modal-add-departure-time" ref="modal-add-departure-time" no-fade centered header-class="py-3" footer-class="py-3" @cancel="handleAddItemModalCancel" @hide="handleAddItemModalCancel" @ok="handleAddItemModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>Hareket saati ekle</h5>
          </div>
        </div>
      </template>
      
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Başlangıç</label>
            <input type="time" class="form-control" v-model="addItemModal.startTime">
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Bitiş</label>
            <input type="time" class="form-control" v-model="addItemModal.endTime">
          </div>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="addItemModal.startTime.length === 0 || addItemModal.endTime.length === 0"
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: ADD DEPARTURE TIME-->

    <!--Begin: EDIT DEPARTURE TIME-->
    <b-modal id="modal-edit-departure-time" ref="modal-edit-departure-time" no-fade centered header-class="py-3" footer-class="py-3" @cancel="handleEditItemModalCancel" @hide="handleEditItemModalCancel" @ok="handleEditItemModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>Hareket saatini düzenle</h5>
          </div>
        </div>
        <button class="btn btn-sm btn-icon btn-light-danger" @click="removeDepartureTime" v-if="departureTimes.length > 1">
          <i class="flaticon2-trash icon-md"></i>
        </button>
      </template>
      
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Başlangıç</label>
            <input type="time" class="form-control" ref="edit_start_time" v-model="editItemModal.startTime">
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Bitiş</label>
            <input type="time" class="form-control" ref="edit_end_time" v-model="editItemModal.endTime">
          </div>
        </div>
      </div>

      <div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="editItemModal.startTime.length === 0 || editItemModal.endTime.length === 0"
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: EDIT DEPARTURE TIME-->
    
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import moment from 'moment';

export default {
  props: ["pnr", "theme", "serviceType"],
  data() {
    return {
      momentApi: null,
      departureTimes: [],
      isLoading: true,
      error: {
        status: false,
        message: ''
      },

      addItemModal: {
        startTime: '',
        endTime: ''
      },
      editItemModal: {
        departureTimeId: null,
        startTime: '',
        endTime: ''
      }
    }
  },
  mounted() {
    this.momentApi = moment;
    this.get();
  },
  computed: {
    addButton() {
      if (this.serviceType === 1) {
        return this.departureTimes.length === 0;
      } else {
        return true;
      }
    }
  },
  watch: {
    pnr() {
      this.get();
    }
  },
  methods: {
    get() {
      ApiService.get(`experience/calendar/event/departure-times/get.req.php?pnr=${this.pnr}`)
      .then(({ data }) => {
        this.departureTimes = data.departureTimes;
        this.error.status = false;
        setTimeout(() => {
          this.isLoading = false;  
        }, 250);
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
        setTimeout(() => {
          this.isLoading = false;  
        }, 250);
      });
    },

    // Add Modal
    handleAddItemModalCancel() {
      this.addItemModal.startTime = '';
      this.addItemModal.endTime = '';
    },
    handleAddItemModalOk() {
      const postData = {
        pnr: this.pnr,
        start: this.addItemModal.startTime,
        end: this.addItemModal.endTime
      }
      
      ApiService.post('experience/calendar/event/departure-times/add.req.php', postData)
      .then(({ data }) => {
        const newDepartureTime = {
          id: data.departureTimeId,
          start: postData.start,
          end: postData.end
        }
        this.departureTimes.push(newDepartureTime);
        this.$emit('notify', 'success', 'Başarılı! Hareket saati kaydedildi.');
      })
      .catch(({ response }) => {
        this.$emit('notify', 'danger', response.data.errorMessage);
      });
    },

    // Edit Modal
    openEditDepartureTimeModal(index) {
      const departureTime = Object.assign({}, this.departureTimes[index]);

      this.editItemModal.departureTimeId = departureTime.id;
      this.editItemModal.startTime = departureTime.start;
      this.editItemModal.endTime = departureTime.end;
      this.$bvModal.show('modal-edit-departure-time');
    },
    handleEditItemModalCancel() {
      this.editItemModal.departureTimeId = null;
      this.editItemModal.startTime = '';
      this.editItemModal.endTime = '';
    },
    handleEditItemModalOk() {
      const start = this.$refs["edit_start_time"].value;
      const end = this.$refs["edit_end_time"].value;
      const id = this.editItemModal.departureTimeId;
      const index = this.departureTimes.findIndex((el) => {
        return el.id == this.editItemModal.departureTimeId;
      });

      const postData = {
        pnr: this.pnr,
        id,
        start,
        end
      }

      ApiService.post('experience/calendar/event/departure-times/set.req.php', postData)
      .then(() => {
        this.departureTimes[index].start = start;
        this.departureTimes[index].end = end;
        this.$emit('notify', 'success', 'Başarılı! Hareket saati güncellendi.');
      })
      .catch(({ response }) => {
        this.$emit('notify', 'danger', response.data.errorMessage);
      });
    },

    // Remove
    removeDepartureTime() {
      const id = this.editItemModal.departureTimeId;
      const index = this.departureTimes.findIndex((el) => {
        return el.id == this.editItemModal.departureTimeId;
      });

      const postData = {
        id,
        pnr: this.pnr
      }

      ApiService.post('experience/calendar/event/departure-times/remove.req.php', postData)
      .then(() => {
        this.departureTimes.splice(index, 1);
        this.$bvModal.hide('modal-edit-departure-time');
        this.$emit('notify', 'success', 'Başarılı! Hareket saati kaldırıldı.');
      })
      .catch(({ response }) => {
        this.$emit('notify', 'danger', response.data.errorMessage);
      });
    }
  }
}
</script>