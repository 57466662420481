<template>
  <div class="card card-stretch card-custom card-shadowless">
    <div class="card-body pl-5 pr-0 pt-10">
      <h3 class="font-weight-bold text-dark">Filtrele</h3>

      <div class="d-flex flex-column justify-content-between h-100">
        <div class="form-group py-10 ">
          <div class="checkbox-list">
            <label v-for="item in filterOptions" :key="item.id" class="checkbox checkbox-pill checkbox-light- font-size-lg font-weight-bold py-3 rounded mb-0 pl-2 bg-hover-light" :class="`checkbox-light-${item.state}`">
              <input type="checkbox" :id="item.id" :value="item.value" v-model="checkedFilters"/>
              <span></span>
              {{ item.title }}
            </label>
          </div>
        </div>  

        <div class="form-group">
          <InlineDatePicker :initial-date="initialDate" @change="dateChanged"/>
        </div>    
      </div>
    </div>
  </div>
</template>

<script>
import InlineDatePicker from "@/view/content/datepicker/InlineDatePicker";
export default {
  props: ["filterOptions", "filterInitialStatus", "initialDate"],
  components: {
    InlineDatePicker
  },
  data() {
    return {
      checkedFilters: [],
    }
  },
  watch: {
    checkedFilters(val) {
      this.$emit('filterChanged', val);
    }
  },
  mounted() {
    this.checkedFilters = [...this.filterInitialStatus];
  },
  methods: {
    dateChanged(val) {
      this.$emit('dateChanged', val);
    }
  }
}
</script>

<style scoped>
.checkbox.checkbox-pill > span {
  border-radius: .35rem !important;
}
</style>