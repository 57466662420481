<template>
  <div>
    <div class="card card-custom my-5">
      <div class="card-body">
        <div class="d-flex flex-row flex-wrap align-items-center justify-content-between">
          <label class="font-weight-bolder text-dark">Satış Fiyatına Dahil</label>
          <button class="btn btn-sm btn-light font-weight-bold" v-if="!error.status" @click="openModal">Düzenle</button>
        </div>
        <div v-if="!error.status">
          <div class="d-flex justify-content-center min-h-100px" v-if="isLoading">
            <div class="spinner spinner-dark"></div>
          </div>
          <div class="font-weight-bold text-muted font-size-sm" v-else>
            <span class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{ threshold }} Pax</span>
          </div>
        </div>
        
        <div v-else>
          <p> {{ error.message }} </p>
        </div>
      </div>
    </div>

    <!--Begin: SET THRESHOLD-->
    <b-modal id="modal-set-threshold" ref="modal-set-threshold" no-fade centered header-class="py-3" footer-class="py-3" @cancel="handleModalCancel" @hide="handleModalCancel" @ok="handleModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>Satış fiyatına dahil katılımcı sayısı</h5>
          </div>
        </div>
      </template>
      
      <div class="form-group">
        <label class="text-body font-weight-bold pl-1">Maksimum Katılımcı</label>
        <input type="number" class="form-control form-control-lg" v-model="modal.threshold">
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="modal.threshold.length === 0 || modal.threshold < 0 || modal.threshold > 99"
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: SET FREE THRESHOLD-->
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  props: ["pnr"],
  data() {
    return {
      threshold: 0,
      serviceType: null,
      error: {
        status: false,
        message: ''
      },
      isLoading: true,

      modal: {
        threshold: 0
      }
    }
  },
  mounted() {
    this.get();
  },
  watch: {
    pnr() {
      this.get();
    }
  },
  methods: {
    get() {
      ApiService.get(`experience/calendar/event/expense-threshold/get.req.php?pnr=${this.pnr}`)
      .then(({ data }) => {
        this.threshold = data.threshold; 
        this.serviceType = data.serviceType;
        this.error.status = false;
        setTimeout(() => {
          this.isLoading = false;  
        }, 250);
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
        setTimeout(() => {
          this.isLoading = false;  
        }, 250);
      });
    },

    openModal() {
      this.modal.threshold = this.threshold;
      this.$bvModal.show('modal-set-threshold');
    },
    handleModalCancel() {
      this.modal.threshold = 0;
    },
    handleModalOk() {
      const threshold = this.modal.threshold;
      const postData = {
        threshold,
        pnr: this.pnr
      }

      ApiService.post('experience/calendar/event/expense-threshold/set.req.php', postData)
      .then(() => {
        this.threshold = threshold;
        this.$emit('notify', 'success', 'Başarılı! Değişiklikler kaydedildi.');
      })
      .catch(({ response }) => {
        this.$emit('notify', 'danger', response.data.errorMessage);
      });
    },
  }
}
</script>

<style>

</style>