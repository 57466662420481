<template>
  <div>
    <ExtraServices :experience-id="experienceId" :pnr="pnr" @notify="notify" v-if="theme !== 2 && theme !== 3" />
  </div>
</template>

<script>
import ExtraServices from "@/view/pages/experience/components/calendar/event_content/ExtraServices";

export default {
  props: ["experienceId", "pnr", "serviceType", "theme"],
  components: {
    ExtraServices
  },
  methods: {
    notify(state, message) {
      this.$emit('notify', state, message);
    }
  }
}
</script>

<style>

</style>