<template>
  <div>
    <div class="card card-custom mt-5">
      <div class="card-body">
        <label class="font-weight-bolder text-dark">Hizmet Özeti</label>
        <p> {{ serviceSummaryText }} </p>
        <button class="btn btn-sm btn-light font-weight-bold" v-if="!error.status" @click="openModal">Düzenle</button>
      </div>
    </div>

    <!--Begin: SET SERVICE SUMMARY-->
    <b-modal id="modal-set-service-summary" ref="modal-set-service-summary" no-fade centered header-class="py-3" footer-class="py-3" @cancel="handleModalCancel" @hide="handleModalCancel" @ok="handleModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>Hizmet özetini düzenle</h5>
          </div>
        </div>
      </template>
      
      <div class="form-group">
        <label class="text-body font-weight-bold pl-1">Türkçe açıkla</label>
        <textarea
          class="form-control mb-2"
          rows="2"
          placeholder="Açıklamayı buraya yazın"
          :maxlength="maxLength"
          v-model="modal.tr"
        ></textarea>
        <span class="text-muted pl-1">
          {{ modal.tr.length }} / {{ maxLength }}
        </span>
      </div>

      <div class="form-group">
        <label class="text-body font-weight-bold pl-1">İngilizce açıkla</label>
        <textarea
          class="form-control mb-2"
          rows="2"
          placeholder="Açıklamayı buraya yazın"
          :maxlength="maxLength"
          v-model="modal.en"
        ></textarea>
        <span class="text-muted pl-1">
          {{ modal.en.length }} / {{ maxLength }}
        </span>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="modal.tr.length === 0 || modal.en.length === 0"
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: SET SERVICE SUMMARY-->
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  props: ["pnr"],
  data() {
    return {
      serviceSummary: {
        tr: '',
        en: ''
      },
      maxLength: 180,
      error: {
        status: false,
        message: ''
      },

      modal: {
        tr: '',
        en: ''
      }
    }
  },
  mounted() {
    this.get();
  },
  computed: {
    serviceSummaryText() {
      if (this.error.status) {
        return this.error.message;
      } else {
        return this.serviceSummary.tr;
      }
    }
  },
  watch: {
    pnr() {
      this.get();
    }
  },
  methods: {
    get() {
      ApiService.get(`experience/calendar/event/service-summary/get.req.php?pnr=${this.pnr}`)
      .then(({ data }) => {
        this.serviceSummary = data.serviceSummary;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    openModal() {
      this.modal.tr = this.serviceSummary.tr;
      this.modal.en = this.serviceSummary.en;
      this.$bvModal.show('modal-set-service-summary');
    },
    handleModalCancel() {
      this.modal.tr = '';
      this.modal.en = '';
    },
    handleModalOk() {
      const summary = Object.assign({}, this.modal);
      const postData = {
        pnr: this.pnr,
        summary: JSON.stringify(summary)
      }

      ApiService.post('experience/calendar/event/service-summary/set.req.php', postData)
      .then(() => {
        this.serviceSummary.tr = summary.tr;
        this.serviceSummary.en = summary.en;
        this.$emit('notify', 'success', 'Başarılı! Hizmet özeti güncellendi.');
      })
      .catch(({ response }) => {
        this.$emit('notify', 'danger', response.data.errorMessage);
      });
    }
  }
}
</script>