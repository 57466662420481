<template>
  <div>
    <div class="card card-custom my-5">
      <div class="card-body">
        <label class="font-weight-bolder text-dark">{{ componentHeaderText }}</label>
        <div v-if="!error.status">
          <div class="d-flex justify-content-center min-h-100px" v-if="isLoading">
            <div class="spinner spinner-dark"></div>
          </div>
          <div v-else>
            <div class="d-flex align-items-center my-5 bg-white bg-hover-light border rounded px-5 py-3" v-for="(item, index) in expenses" :key="item.id" @click="openModal(index)">
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <a href="javascript:;" class="font-weight-bold text-dark-75 font-size-md mb-1">{{ experienceConfig(`participant.${item.id}.tr`) }}</a>
              </div>
              <span class="font-weight-bolder text-dark py-1 font-size-md">{{ expensePriceText(item.price) }}</span>
            </div>
          </div>
        </div>

         <div v-else>
          <p> {{ error.message }} </p>
        </div>

      </div>
    </div>

    <!--Begin: SET PRICE-->
    <b-modal id="modal-set-ppe" ref="modal-set-ppe" no-fade centered header-class="py-3" footer-class="py-3" @cancel="handleModalCancel" @hide="handleModalCancel" @ok="handleModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>Ücret düzenle</h5>
          </div>
        </div>
      </template>
      
      <div class="form-group">
        <label class="text-body font-weight-bold pl-1">{{ experienceConfig(`participant.${modal.participantId}.tr`) }}</label>
        <div class="input-group">
          <div class="input-group-prepend"><span class="input-group-text">TRY</span></div>
          <input type="number" class="form-control form-control-lg" v-model="modal.price">
          <div class="input-group-append"><span class="input-group-text">.00</span></div>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="modal.price.length === 0 || modal.price < 0"
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: SET PRICE-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from '@/core/services/api.service';

export default {
  props: ["pnr"],
  data() {
    return {
      expenses: [],
      serviceType: null,
      isLoading: true,
      error: {
        status: false,
        message: ''
      },
      modal: {
        expenseIndex: null,
        participantId: null,
        price: 0,
      }
    }
  },
  mounted() {
    this.get();
  },
  computed: {
    ...mapGetters(["experienceConfig"]),

    componentHeaderText() {
      if (this.serviceType === 1) {
        return 'Kişi Başı Ücret';
      } else if (this.serviceType === 2) {
        return 'İlave Katılımcı İçin Kişi Başı Ücret';
      } else {
        return '';
      }
    }
  },
  watch: {
    pnr() {
      this.get();
    }
  },
  methods: {
    get() {
      ApiService.get(`experience/calendar/event/per-person-expenses/get.req.php?pnr=${this.pnr}`)
      .then(({ data }) => {
        this.expenses = data.expenses;
        this.serviceType = data.serviceType;
        this.error.status = false;
        setTimeout(() => {
          this.isLoading = false;  
        }, 250);
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
        setTimeout(() => {
          this.isLoading = false;  
        }, 250);
      });
    },

    expensePriceText(price) {
      if (price > 0) {
        return price + ' TRY';
      } else {
        return 'Ücretsiz';
      }
    },

    openModal(index) {
      const expense = Object.assign({}, this.expenses[index]);
      this.modal.expenseIndex = index;
      this.modal.participantId = expense.id;
      this.modal.price = expense.price;
      this.$bvModal.show('modal-set-ppe');
    },
    handleModalCancel() {
      this.modal.expenseIndex = null;
      this.modal.participantId = null;
      this.modal.price = 0;
    },
    handleModalOk() {
      const index = this.modal.expenseIndex;
      const price = this.modal.price;
      const participant = this.modal.participantId;

      const postData = {
        participant,
        price,
        pnr: this.pnr
      }

      ApiService.post('experience/calendar/event/per-person-expenses/set.req.php', postData)
      .then(() => {
        this.expenses[index].price = price;
        this.$emit('notify', 'success', 'Başarılı! Kişi başı ücret güncellendi.');
      })
      .catch(({ response }) => {
        this.$emit('notify', 'danger', response.data.errorMessage);
      });
    }
  }
}
</script>

<style>

</style>