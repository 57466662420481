<template>
  <div class="mb-0">
  <div class="card card-custom card-stretch card-shadowless bg-gray-100" ref="event_content_card" id="event_content_card">
    <div class="card-body">
      <div class="card-scroll">
        
        <div v-if="pnr != ''">
          <div class="card card-custom">
            <div class="card-body">
              <div class="d-flex flex-row flex-wrap align-items-center justify-content-between">
                <h3 class="font-weight-bold text-dark mb-0">#{{pnr}}</h3>
                <div>
                  <b-button
                    v-b-tooltip.hover
                    type="button"
                    class="btn btn-icon btn-light"
                    data-toggle="tooltip"
                    title="Satışa Kapat"
                    @click="setStatus(3)"
                    v-if="eventStatus === 1"
                  >
                  <i class="flaticon2-lock icon-lg"></i>
                  </b-button>
                  <b-button
                    v-b-tooltip.hover
                    type="button"
                    class="btn btn-icon btn-light"
                    data-toggle="tooltip"
                    title="Satışa Aç"
                    @click="setStatus(1)"
                    v-if="eventStatus === 3"
                  >
                  <i class="flaticon2-calendar-5 icon-lg"></i>
                  </b-button>
                  <b-button
                    v-b-tooltip.hover
                    type="button"
                    class="btn btn-icon btn-light ml-3"
                    data-toggle="tooltip"
                    title="Kopyala"
                    @click="$bvModal.show('modal-copy-event')"
                  >
                  <i class="flaticon2-copy icon-lg"></i>
                  </b-button>
                  <b-button
                    v-b-tooltip.hover
                    type="button"
                    class="btn btn-icon btn-light-danger ml-3"
                    data-toggle="tooltip"
                    title="Müsaitliği Kaldır"
                    @click="setStatus(0)"
                    v-if="eventStatus === 1 && soldSeat === 0"
                  >
                  <i class="flaticon2-trash icon-lg"></i>
                  </b-button>
                </div>
              </div>
            </div>
          </div>
          <div class="card card-custom my-5">
            <div class="card-body p-5">
              <ul class="nav nav-dark nav-bold nav-pills d-flex flex-row flex-wrap align-items-center justify-content-between">
                <li class="nav-item">
                  <button class="nav-link" :class="{'active': activeTab === 'general'}" data-toggle="tab" @click="activeTab = 'general'">
                    <span class="nav-text">Genel</span>
                  </button>
                </li>
                <li class="nav-item">
                  <button class="nav-link" :class="{'active': activeTab === 'price'}" data-toggle="tab" @click="activeTab = 'price'">
                    <span class="nav-text">Ücret</span>
                  </button>
                </li>
                <li class="nav-item">
                  <button class="nav-link" :class="{'active': activeTab === 'extra'}" data-toggle="tab" @click="activeTab = 'extra'">
                    <span class="nav-text">Ekstra</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <GeneralSection :service-type="serviceType" :theme="theme" :experience-id="experienceId" :pnr="pnr" @notify="notify" @soldseat="getSoldSeat" v-if="activeTab === 'general'"/>
          <PriceSection :service-type="serviceType" :theme="theme" :experience-id="experienceId" :pnr="pnr" @notify="notify" v-if="activeTab === 'price'"/>
          <ExtraSection :service-type="serviceType" :theme="theme" :experience-id="experienceId" :pnr="pnr" @notify="notify" v-if="activeTab === 'extra'"/>

          <!--Begin: CALENDAR-->
          <b-modal id="modal-copy-event" ref="modal-copy-event" size="400" no-fade centered header-class="py-3" footer-class="py-3" @ok="handleModalOk">
            <template #modal-header="{ cancel }">
              <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
              <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
                <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
                  <h5>Tarih aralığı seçin</h5>
                </div>
              </div>
            </template>
            
            <div class="pl-5">
              <DateRangePicker :initial-date="{ start: datePickerModal.initialDate.start, end: datePickerModal.initialDate.end }" :min-date="datePickerModal.initialDate.start" @change="dateChanged"/>
            </div>

            <template #modal-footer="{ ok, cancel }">
              <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
                <button 
                  type="button" 
                  class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
                  @click="cancel"
                >İptal et</button>

                <button 
                  type="button" 
                  class="btn btn-dark font-weight-bolder px-5 py-4" 
                  @click="ok"
                >
                  Bu tarihlere kopyala
                </button>
              </div>
            </template>

          </b-modal>
          <!--End: CALENDAR-->
        </div>
      </div>

    </div>
  </div>
    <Notification 
      :state="notification.state" 
      :message="notification.message"
      v-if="notification.status"
    />
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import KTLayoutStretchedCard from "@/assets/js/layout/extended/stretched-card.js";
import GeneralSection from "@/view/pages/experience/components/calendar/event_content/General";
import PriceSection from "@/view/pages/experience/components/calendar/event_content/Price";
import ExtraSection from "@/view/pages/experience/components/calendar/event_content/Extra";
import Notification from "@/view/content/notification/Notification";
import DateRangePicker from "@/view/content/datepicker/InlineDateRangePicker";

export default {
  props: ["experienceId", "pnr", "serviceType", "theme", "eventStatus"],
  components: {
    GeneralSection,
    PriceSection,
    ExtraSection,
    Notification,
    DateRangePicker
  },
  data() {
    return {
      activeTab: 'general',
      notification: {
        status: false,
        state: '',
        message: ''
      },
      soldSeat: 0,
      datePickerModal: {
        initialDate: {
          start: moment().format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD')
        },
        start: '',
        end: ''
      },
    }
  },
  watch: {
    activeTab() {
      KTLayoutStretchedCard.init(this.$refs["event_content_card"]);  
    }
  },
  mounted() {
    KTLayoutStretchedCard.init(this.$refs["event_content_card"]);
  },
  methods: {
    notify(state, message) {
      this.notification.state = state;
      this.notification.message = message;
      this.notification.status = true;
      
      this.notification.timeout = setTimeout(() => {
        this.notification.status = false;
      }, 4000);
    },

    getSoldSeat(val) {
      this.soldSeat = val;
    },

    setStatus(status) {   
      const postData = {
        pnr: this.pnr,
        status: status
      }

      ApiService.post('experience/calendar/event/status/set.req.php', postData)
      .then(() => {
        this.notify('success', 'Başarılı! Değişiklikler kaydedildi.');
        this.$emit("event-status-updated");
      })
      .catch(({ response }) => {
        this.notify('danger', response.data.errorMessage);
      });
    },

    dateChanged(dateRange) {
      this.datePickerModal.start = dateRange.start;
      this.datePickerModal.end = dateRange.end;
    },

    handleModalOk() {
      this.$emit('copy-event', this.pnr, this.datePickerModal.start, this.datePickerModal.end);
    }
  }
}
</script>

<style>
div.modal .modal-dialog.modal-400 {
  width: 400px !important;
}
</style>