<template>
  <div>
    <div class="card card-custom my-5">
      <div class="card-body">
        <div class="d-flex flex-row flex-wrap align-items-center justify-content-between">
          <label class="font-weight-bolder text-dark">Satın Alma</label>
          <button class="btn btn-sm btn-light font-weight-bold" v-if="!error.status" @click="openModal">Düzenle</button>
        </div>
        <div v-if="!error.status">
          <div class="d-flex justify-content-center min-h-100px" v-if="isLoading">
            <div class="spinner spinner-dark"></div>
          </div>
          <div class="font-weight-bold text-muted font-size-sm" v-else>
            <span class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{ cutOffText }}</span> {{ secondaryText }}
          </div>
        </div>
        
        <div v-else>
          <p> {{ error.message }} </p>
        </div>

      </div>
    </div>

     <!--Begin: SET CUT OFF TIME-->
    <b-modal id="modal-set-cut-off-time" ref="modal-set-cut-off-time" no-fade centered header-class="py-3" footer-class="py-3" @cancel="handleModalCancel" @hide="handleModalCancel" @ok="handleModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>Ne zamana kadar rezervasyon yapılabilir?</h5>
          </div>
        </div>
      </template>

      <div class="form-group">
        <div class="radio-list">
          <label class="radio radio-dark font-size-lg font-weight-bold mb-6">
            <input type="radio" name="radioCutOff" value="1" v-model="modal.selectedOption"/>
            <span></span>
            Hareket saatine kadar
          </label>
          <label class="radio radio-dark font-size-lg font-weight-bold mb-6">
            <input type="radio" name="radioCutOff" value="2" v-model="modal.selectedOption"/>
            <span></span>
            Tanımlı bir zamana kadar
          </label>
        </div>
      </div>  
      
      <div class="form-group" v-if="modal.selectedOption == 2">
        <label class="text-body font-weight-bold pl-1">Süre</label>
        <input type="time" class="form-control form-control-lg" v-model="modal.cutOff">
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="isModalSaveBtnDisabled"
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: SET CUT OFF TIME-->
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import moment from 'moment';

export default {
  props: ["pnr"],
  data() {
    return { 
      momentApi: null, 
      cutOff: null,
      error: {
        status: false,
        message: ''
      },
      isLoading: true,

      modal: {
        selectedOption: null,
        cutOff: ''
      }
    }
  },
  mounted() {
    this.momentApi = moment;
    this.get();
  },
  computed: {
    cutOffText() {
      const hour = this.cutOff.hour();
      const minute = this.cutOff.minute();
      let text = '';
      if (hour > 0 && minute === 0) {
        text = hour + ' Saat';
      } else if (hour > 0 && minute > 0) {
        text = hour + ':' + minute + ' Dakika';
      } else if (hour === 0 && minute > 0) {
        text = minute + ' Dakika';
      } else if (hour === 0 && minute === 0) {
        text = 'Hareket saatine kadar';
      }
      return text;
    },
    secondaryText() {
      const hour = this.cutOff.hour();
      const minute = this.cutOff.minute();
      if (hour > 0 || minute > 0) {
        return 'önceye kadar';
      } else {
        return '';
      }
    },
    isModalSaveBtnDisabled() {
      if (this.modal.selectedOption === null)  {
        return true;
      }
      if (this.modal.selectedOption == 2 && this.modal.cutOff.length === 0) {
        return true;
      }
      return false;
    }
  },
  watch: {
    pnr() {
      this.get();
    }
  },
  methods: {
    get() {
      ApiService.get(`experience/calendar/event/cut-off/get.req.php?pnr=${this.pnr}`)
      .then(({ data }) => {
        this.cutOff = moment(data.cutOff, 'HH:mm:ss');
        this.error.status = false;
        setTimeout(() => {
          this.isLoading = false;  
        }, 250);
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
        setTimeout(() => {
          this.isLoading = false;  
        }, 250);
      });
    },

    openModal() {
      const hour = this.cutOff.hour();
      const minute = this.cutOff.minute();
      
      if (hour + minute == 0) {
        this.modal.selectedOption = 1;
        this.modal.cutOff = '00:00';
      } else {
        this.modal.selectedOption = 2;
        this.modal.cutOff = this.cutOff.format('HH:mm');
      }
      this.$bvModal.show('modal-set-cut-off-time');
    },
    handleModalCancel() {
      this.modal.selectedOption = null;
      this.modal.cutOff = '';
    },
    handleModalOk() {
      const postData = {
        pnr: this.pnr,
        cutOff: this.modal.selectedOption == 1 ? '00:00' : this.modal.cutOff
      }

      ApiService.post('experience/calendar/event/cut-off/set.req.php', postData)
      .then(() => {
        this.cutOff = moment(postData.cutOff, 'HH:mm:ss');
        this.$emit('notify', 'success', 'Başarılı! Değişiklikler kaydedildi.');
      })
      .catch(({ response }) => {
        this.$emit('notify', 'danger', response.data.errorMessage);
      });
    }
  }
}
</script>