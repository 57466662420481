<template>
  <div>
    <FixedExpenses :pnr="pnr" @notify="notify" v-if="serviceType === 2" />
    <ExpenseTreshold :pnr="pnr" @notify="notify" v-if="serviceType === 2" />
    <PerPersonExpenses :pnr="pnr" @notify="notify" />
    <HotelExpenses :pnr="pnr" @notify="notify" v-if="theme === 2" />
    <ApartExpenses :pnr="pnr" @notify="notify" v-if="theme === 3" />
  </div>
</template>

<script>
import PerPersonExpenses from "@/view/pages/experience/components/calendar/event_content/PerPersonExpenses";
import FixedExpenses from "@/view/pages/experience/components/calendar/event_content/FixedExpenses";
import ExpenseTreshold from "@/view/pages/experience/components/calendar/event_content/ExpenseTreshold";
import HotelExpenses from "@/view/pages/experience/components/calendar/event_content/HotelExpenses";
import ApartExpenses from "@/view/pages/experience/components/calendar/event_content/ApartExpenses";

export default {
  props: ["experienceId", "pnr", "serviceType", "theme"],
  components: {
    PerPersonExpenses,
    FixedExpenses,
    ExpenseTreshold,
    HotelExpenses,
    ApartExpenses
  },
  methods: {
    notify(state, message) {
      this.$emit('notify', state, message);
    }
  }
}
</script>

<style>

</style>