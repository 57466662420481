<template>
  <VueDatePicker 
    v-model="date" 
    class="inline-drp"
    no-header 
    range 
    inline
    placeholder="Tarih aralığı" 
    color="#F64E60" 
    clearable 
    format="D MMM YYYY" 
    :min-date="minDate"
    :max-date="maxDate" 
    :locale="locale"
     @onChange="onChange"
  >
  </VueDatePicker>
</template>

<script>
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';

export default {
  props: ["initialDate", "minDate", "maxDate"],
  components: {
    VueDatePicker,
  },
  data() {
    return {
      date: null,
      locale: { 
        lang: { 
          name: 'tr',
          weekdays: 'Pazar_Pazartesi_Salı_Çarşamba_Perşembe_Cuma_Cumartesi'.split('_'),
          weekdaysShort: 'Paz_Pzt_Sal_Çar_Per_Cum_Cmt'.split('_'),
          weekStart: 1,
          months: 'Ocak_Şubat_Mart_Nisan_Mayıs_Haziran_Temmuz_Ağustos_Eylül_Ekim_Kasım_Aralık'.split('_'),
          monthsShort: 'Oca_Şub_Mar_Nis_May_Haz_Tem_Ağu_Eyl_Eki_Kas_Ara'.split('_'),
          formats: {
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            L: 'DD/MM/YYYY',
            LL: 'D MMMM YYYY',
            LLL: 'D MMMM YYYY HH:mm',
            LLLL: 'dddd D MMMM YYYY HH:mm',
          },
          ordinal: n => `${n}º`,
          buttonCancel: 'Vazgeç',
          buttonValidate: 'Tamam'
        } 
      }
    }
  },
  computed: {
    getInitialDate() {
      return this.initialDate;
    },
  },
  mounted() {
    this.date = this.getInitialDate;
  },
  methods: {
    onChange() {
      this.$emit('change', this.date);
    }
  }
}
</script>

<style>
.inline-drp {
  box-shadow: none !important;
}
.inline-drp .vd-picker {
  background-color: transparent !important;
  box-shadow: none !important;
}
.inline-drp .vd-picker__table-day--selected .vd-picker__table-day__text {
  font-weight: 700 !important;
}
.inline-drp .vd-picker__controls button.vd-picker__controls-next {
  order: 1;
}
.inline-drp .vd-picker__controls button.vd-picker__controls-prev {
  order: 0;
}
.inline-drp .vd-picker__controls-wrapper {
  order: 2;
}
.inline-drp .vd-picker__controls-month {
  justify-content: flex-end !important;
}
.inline-drp .vd-picker__controls-year {
  justify-content: flex-start !important;
  flex-grow: 0 !important;
}
.inline-drp .vd-picker__table {
  padding: 0 !important;
}
</style>