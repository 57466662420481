<template>
  <div>
    <div class="card card-custom my-5">
      <div class="card-body">
        <div class="d-flex flex-row flex-wrap align-items-center justify-content-between">
          <label class="font-weight-bolder text-dark">Ücretsiz İptal</label>
          <button class="btn btn-sm btn-light font-weight-bold" v-if="!error.status" @click="openModal">Düzenle</button>
        </div>
        <div v-if="!error.status">
          <div class="d-flex justify-content-center min-h-100px" v-if="isLoading">
            <div class="spinner spinner-dark"></div>
          </div>
          <div class="font-weight-bold text-muted font-size-sm" v-else>
            <span class="text-dark-75 font-size-h3 font-weight-bolder mr-2">{{ durationText }}</span> {{ secondaryText }}
          </div>
        </div>
        
        <div v-else>
          <p> {{ error.message }} </p>
        </div>

      </div>
    </div>
    <!--Begin: SET FREE CANCELLATION-->
    <b-modal id="modal-set-free-cancellation" ref="modal-set-free-cancellation" no-fade centered header-class="py-3" footer-class="py-3" @cancel="handleModalCancel" @hide="handleModalCancel" @ok="handleModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>Ücretsiz iptal koşulları</h5>
          </div>
        </div>
      </template>
      
      <div class="form-group">
        <div class="radio-list">
          <label class="radio radio-dark font-size-lg font-weight-bold mb-6">
            <input type="radio" name="radioFreeCancellation" value="0" v-model="modal.selectedOption"/>
            <span></span>
            Ücret iadesi yok
          </label>
          <label class="radio radio-dark font-size-lg font-weight-bold mb-6">
            <input type="radio" name="radioFreeCancellation" value="12" v-model="modal.selectedOption"/>
            <span></span>
            12 saate kadar ücretsiz iptal
          </label>
          <label class="radio radio-dark font-size-lg font-weight-bold mb-6">
            <input type="radio" name="radioFreeCancellation" value="24" v-model="modal.selectedOption"/>
            <span></span>
            24 saate kadar ücretsiz iptal
          </label>
          <label class="radio radio-dark font-size-lg font-weight-bold mb-6">
            <input type="radio" name="radioFreeCancellation" value="36" v-model="modal.selectedOption"/>
            <span></span>
            36 saate kadar ücretsiz iptal
          </label>
          <label class="radio radio-dark font-size-lg font-weight-bold mb-6">
            <input type="radio" name="radioFreeCancellation" value="48" v-model="modal.selectedOption"/>
            <span></span>
            48 saate kadar ücretsiz iptal
          </label>
          <label class="radio radio-dark font-size-lg font-weight-bold mb-6">
            <input type="radio" name="radioFreeCancellation" value="72" v-model="modal.selectedOption"/>
            <span></span>
            72 saate kadar ücretsiz iptal
          </label>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="modal.selectedOption == null"
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: SET FREE CANCELLATION-->
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  props: ["pnr"],
  data() {
    return {  
      status: null,
      duration: null,
      error: {
        status: false,
        message: ''
      },
      isLoading: true,
      modal: {
        selectedOption: null
      }
    }
  },
  mounted() {
    this.get();
  },
  computed: {
    durationText() {
      if (this.status) {
        return this.duration + ' Saat';
      } else {
        return 'Ücret iadesi yok';
      }
    },
    secondaryText() {
      if (this.status) {
        return 'önceye kadar';
      } else {
        return '';
      }
    }
  },
  watch: {
    pnr() {
      this.get();
    }
  },
  methods: {
    get() {
      ApiService.get(`experience/calendar/event/free-cancellation/get.req.php?pnr=${this.pnr}`)
      .then(({ data }) => {
        this.status = data.freeCancellation.status === 1;
        this.duration = data.freeCancellation.duration;
        this.error.status = false;
        setTimeout(() => {
          this.isLoading = false;  
        }, 250);
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
        setTimeout(() => {
          this.isLoading = false;  
        }, 250);
      });
    },

    openModal() {
      if (!this.status) {
        this.modal.selectedOption = 0;
      } else {
        this.modal.selectedOption = this.duration;
      }
      this.$bvModal.show('modal-set-free-cancellation');
    },
    handleModalCancel() {
      this.modal.selectedOption = null;
    },

    handleModalOk() {
      const postData = {
        pnr: this.pnr,
        duration: this.modal.selectedOption
      }

      ApiService.post('experience/calendar/event/free-cancellation/set.req.php', postData)
      .then(() => {
        if (postData.duration > 0) {
          this.status = true;
          this.duration = postData.duration;
        } else {
          this.status = false;
          this.duration = 0;
        }
        this.$emit('notify', 'success', 'Başarılı! Değişiklikler kaydedildi.');
      })
      .catch(({ response }) => {
        this.$emit('notify', 'danger', response.data.errorMessage);
      });
    }
  }
}
</script>