<template>
  <div>
    <div class="card card-custom my-5">
      <div class="card-body">
        <label class="font-weight-bolder text-dark">Konaklama Ücretleri</label>
        <div v-if="!error.status">
          <div class="d-flex justify-content-center min-h-100px" v-if="isLoading">
            <div class="spinner spinner-dark"></div>
          </div>
          <div v-else>
            <div class="d-flex align-items-center my-5 bg-white bg-hover-light border rounded px-5 py-3" v-for="(item, index) in selectedOptions" :key="index" @click="openEditOptionModal(index)">
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <a href="javascript:;" class="font-weight-bold text-dark-75 font-size-md">{{ accommodationOptionText(item.room) }}</a>
              </div>
              <span class="font-weight-bolder text-dark py-1 font-size-md">{{ expensePriceText(item.sale) }}</span>
            </div>
            <button class="btn btn-sm btn-light font-weight-bold" @click="$bvModal.show('modal-hotel-option-listings')" v-if="!error.status">Konaklama seçeneği ekle</button>
          </div>
        </div>
        
        <div v-else>
          <p> {{ error.message }} </p>
        </div>
      </div>
    </div>

    <!--Begin: OPTION LISTINGS-->
    <b-modal id="modal-hotel-option-listings" ref="modal-hotel-option-listings" no-fade scrollable centered header-class="py-3" footer-class="py-3" @cancel="handleOptionListingsModalCancel" @hide="handleOptionListingsModalCancel" @ok="handleOptionListingsModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>Konaklama seçenekleri</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div>
          <div class="form-group" v-for="(item, index) in availableOptions" :key="index">
            <label class="option selectable mb-3" :class="{selected: checkedOptions.includes(item)}">
              <span class="option-control">
                <span class="checkbox checkbox-dark checkbox-pill">
                  <input
                    type="checkbox"
                    name="accommodation_option"
                    :value="item"
                    v-model="checkedOptions"
                  />
                  <span></span>
                </span>
              </span>
              <span class="option-label">
                <span class="option-head">
                  <div class="option-title">
                    {{ accommodationOptionText(item.room) }}
                  </div>
                </span>
              </span>
            </label>
          </div>
          <div class="form-group">
            <button class="btn btn-sm btn-light font-weight-bold" @click="openCustomOptionModal">Özel seçenek</button>
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
          >İptal et</button>
          
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="checkedOptions.length === 0"
          >
            Ekle
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: OPTION LISTINGS-->

    <!--Begin: EDIT OPTION-->
    <b-modal id="modal-edit-hotel-option" ref="modal-edit-hotel-option" no-fade scrollable centered header-class="py-3" footer-class="py-3" @cancel="handleEditOptionModalCancel" @hide="handleEditOptionModalCancel" @ok="handleEditOptionModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>{{ editModal.title }}</h5>
          </div>
        </div>
        <button class="btn btn-sm btn-icon btn-light-danger" @click="removeOption">
          <i class="flaticon2-trash icon-md"></i>
        </button>
      </template>
      
      <div class="max-h-300px">
        <div class="row">
          <div class="form-group col-xl-6">
            <label class="text-body font-weight-bold pl-1">Maliyet</label>
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text">TRY</span></div>
              <input type="number" class="form-control form-control-lg" v-model="editModal.cost">
              <div class="input-group-append"><span class="input-group-text">.00</span></div>
            </div>
          </div>
          <div class="form-group col-xl-6">
            <label class="text-body font-weight-bold pl-1">Satış Fiyatı</label>
            <div class="input-group ">
              <div class="input-group-prepend"><span class="input-group-text">TRY</span></div>
              <input type="number" class="form-control form-control-lg" v-model="editModal.sale">
              <div class="input-group-append"><span class="input-group-text">.00</span></div>
            </div>
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
          >İptal et</button>
          
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="isEditModalSaveDisabled"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: EDIT OPTION-->

    <!--Begin: ADD CUSTOM OPTION-->
    <b-modal id="modal-custom-hotel-option" ref="modal-custom-hotel-option" no-fade scrollable centered header-class="py-3" footer-class="py-3" @cancel="handleCustomOptionModalCancel" @hide="handleCustomOptionModalCancel" @ok="handleCustomOptionModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ addModal.activeStep }} ADIM/{{addModal.steps.length}}</label>
            <h5>{{ addModal.steps[addModal.activeStep - 1].title }}</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div v-if="addModal.activeStep === 1">
          <div class="row">
            <div class="form-group col-xl-4">
              <label class="text-body font-weight-bold pl-1">Yetişkin</label>
              <input type="text" class="form-control form-control-lg" v-model="addModal.room.adult">
            </div>
            <div class="form-group col-xl-4">
              <label class="text-body font-weight-bold pl-1">Çocuk</label>
              <input type="text" class="form-control form-control-lg" v-model="addModal.room.child">
            </div>
            <div class="form-group col-xl-4">
              <label class="text-body font-weight-bold pl-1">Bebek</label>
              <input type="text" class="form-control form-control-lg" v-model="addModal.room.child_2">
            </div>
          </div>
        </div>

        <div v-if="addModal.activeStep === 2">
          <div class="row">
            <div class="form-group col-xl-6">
              <label class="text-body font-weight-bold pl-1">Maliyet</label>
              <div class="input-group">
                <div class="input-group-prepend"><span class="input-group-text">TRY</span></div>
                <input type="number" class="form-control form-control-lg" v-model="addModal.cost">
                <div class="input-group-append"><span class="input-group-text">.00</span></div>
              </div>
            </div>
            <div class="form-group col-xl-6">
              <label class="text-body font-weight-bold pl-1">Satış Fiyatı</label>
              <div class="input-group ">
                <div class="input-group-prepend"><span class="input-group-text">TRY</span></div>
                <input type="number" class="form-control form-control-lg" v-model="addModal.sale">
                <div class="input-group-append"><span class="input-group-text">.00</span></div>
              </div>
            </div>
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
            v-if="addModal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="addModal.activeStep = addModal.activeStep - 1" 
            v-if="addModal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>
          
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="isAddModalSaveDisabled"
            v-if="addModal.activeStep == addModal.steps.length"
          >
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="isAddModalSaveDisabled"
            @click="addModal.activeStep = addModal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: ADD CUSTOM OPTION-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from '@/core/services/api.service';

export default {
  props: ["pnr"],
  data() {
    return {
      options: [
        {id: null, room: { adult: 1, child: 0, child_2: 0 }, cost: null, sale: null},
        {id: null, room: { adult: 1, child: 1, child_2: 0 }, cost: null, sale: null},
        {id: null, room: { adult: 1, child: 0, child_2: 1 }, cost: null, sale: null},
        {id: null, room: { adult: 1, child: 1, child_2: 1 }, cost: null, sale: null},
        {id: null, room: { adult: 1, child: 2, child_2: 0 }, cost: null, sale: null},
        {id: null, room: { adult: 1, child: 0, child_2: 2 }, cost: null, sale: null},
        {id: null, room: { adult: 1, child: 2, child_2: 1 }, cost: null, sale: null},
        {id: null, room: { adult: 1, child: 1, child_2: 2 }, cost: null, sale: null},
        {id: null, room: { adult: 1, child: 3, child_2: 0 }, cost: null, sale: null},
        {id: null, room: { adult: 1, child: 0, child_2: 3 }, cost: null, sale: null},

        {id: null, room: { adult: 2, child: 0, child_2: 0 }, cost: null, sale: null},
        {id: null, room: { adult: 2, child: 1, child_2: 0 }, cost: null, sale: null},
        {id: null, room: { adult: 2, child: 0, child_2: 1 }, cost: null, sale: null},
        {id: null, room: { adult: 2, child: 1, child_2: 1 }, cost: null, sale: null},
        {id: null, room: { adult: 2, child: 2, child_2: 0 }, cost: null, sale: null},
        
        {id: null, room: { adult: 3, child: 0, child_2: 0 }, cost: null, sale: null},
        {id: null, room: { adult: 3, child: 1, child_2: 0 }, cost: null, sale: null},
        {id: null, room: { adult: 3, child: 0, child_2: 1 }, cost: null, sale: null},
        
        {id: null, room: { adult: 0, child: 1, child_2: 0 }, cost: null, sale: null},
        {id: null, room: { adult: 0, child: 2, child_2: 0 }, cost: null, sale: null},
        {id: null, room: { adult: 0, child: 3, child_2: 0 }, cost: null, sale: null},
      ],
      checkedOptions: [],
      selectedOptions: [],
      editModal: {
        title: '',
        index: null,
        id: null,
        room: null,
        cost: '',
        sale: ''
      },
      addModal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Konaklama tipini girin'
          },
          {
            number: 2,
            title: 'Konaklama ücretini girin'
          }
        ],
        room: {adult: '', child: '', child_2: ''},
        cost: '',
        sale: ''
      },
      isLoading: true,
      error: {
        status: false,
        message: ''
      },
    }
  },
  computed: {
    ...mapGetters(["experienceConfig"]),
    
    availableOptions() {
      return this.options.filter(({ room: room_1 }) => !this.selectedOptions.some(({ room: room_2 }) => room_1.adult === room_2.adult && room_1.child === room_2.child && room_1.child_2 === room_2.child_2 ));
    },

    isEditModalSaveDisabled() {
      if (this.editModal.cost === null || this.editModal.cost.length === 0 || this.editModal.cost < 0) {
        return true;
      }
      if (this.editModal.sale === null || this.editModal.sale.length === 0 || this.editModal.sale < 0) {
        return true;
      }
      return false;
    },

    isAddModalSaveDisabled() {
      if (this.addModal.activeStep === 1) {
        if (this.addModal.room.adult.length === 0 || this.addModal.room.child.length === 0 || this.addModal.room.child_2.length === 0) {
          return true;
        }
      } else if (this.addModal.activeStep === 2) {
        if (this.addModal.cost.length === 0 || this.addModal.cost < 0) {
          return true;
        }
        if (this.addModal.sale.length === 0 || this.addModal.sale < 0) {
          return true;
        }
      }
      return false;
    }
  },
  mounted() {
    this.get();
  },
   watch: {
    pnr() {
      this.get();
    }
  },
  methods: {
    get() {
      ApiService.get(`experience/calendar/event/hotel-expenses/get.req.php?pnr=${this.pnr}`)
      .then(({ data }) => {
        this.selectedOptions = data.expenses;
        this.error.status = false;
        setTimeout(() => {
          this.isLoading = false;  
        }, 250);
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
        setTimeout(() => {
          this.isLoading = false;  
        }, 250);
      });
    },

    set(obj) {
      const index = obj.index;
      const id = obj.id;
      const sale = obj.sale;
      const cost = obj.cost;

      const postData = {
        id,
        cost,
        sale,
        pnr: this.pnr
      }
      ApiService.post('experience/calendar/event/hotel-expenses/set.req.php', postData)
      .then(() => {
        this.selectedOptions[index].sale = sale;
        this.selectedOptions[index].cost = cost;
        this.$emit('notify', 'success', 'Başarılı! Değişiklikler kaydedildi.');
      })
      .catch(({ response }) => {
        this.$emit('notify', 'danger', response.data.errorMessage);
      });
    },

    add(obj) {
      const index = obj.index;
      const sale = obj.sale;
      const cost = obj.cost;
      const room = obj.room;

      const postData = {
        cost,
        sale,
        adult: room.adult,
        child: room.child,
        child_2: room.child_2,
        pnr: this.pnr
      }
      ApiService.post('experience/calendar/event/hotel-expenses/add.req.php', postData)
      .then(({ data }) => {
        this.selectedOptions[index].id = data.expenseId;
        this.selectedOptions[index].cost = cost;
        this.selectedOptions[index].sale = sale;
        this.$emit('notify', 'success', 'Başarılı! Değişiklikler kaydedildi.');
      })
      .catch(({ response }) => {
        this.$emit('notify', 'danger', response.data.errorMessage);
      });
    },

    remove(obj) {
      const index = obj.index;
      const id = obj.id;

      const postData = {
        id,
        pnr: this.pnr
      }
      ApiService.post('experience/calendar/event/hotel-expenses/remove.req.php', postData)
      .then(() => {
        this.selectedOptions.splice(index, 1);
        this.$emit('notify', 'success', 'Başarılı! Değişiklikler kaydedildi.');
      })
      .catch(({ response }) => {
        this.$emit('notify', 'danger', response.data.errorMessage);
      });
    },

    accommodationOptionText(obj) {
      let text = '';
      if (obj.adult > 0) {
        text += `${obj.adult} ${this.experienceConfig('participant.adult.tr')}, `
      }
      if (obj.child > 0) {
        text += `${obj.child} ${this.experienceConfig('participant.child.tr')}, `
      }
      if (obj.child_2 > 0) {
        text += `${obj.child_2} ${this.experienceConfig('participant.infant.tr')}, `
      }
      return text.slice(0, -2);
    },

    handleOptionListingsModalCancel() {
      this.checkedOptions = [];
    },
    handleOptionListingsModalOk() {
      this.selectedOptions.push(...this.checkedOptions);
    },
    expensePriceText(price) {
      if (price === null) {
        return '';
      } else if (price > 0) {
        return price + ' TRY';
      } else {
        return 'Ücretsiz';
      }
    },

    openEditOptionModal(index ){
      const option = Object.assign({}, this.selectedOptions[index]);

      this.editModal.title = this.accommodationOptionText(option.room);
      this.editModal.index = index;
      this.editModal.id = option.id;
      this.editModal.room = option.room;
      this.editModal.cost = option.cost;
      this.editModal.sale = option.sale;
      this.$bvModal.show('modal-edit-hotel-option');
    },
    handleEditOptionModalCancel() {
      this.editModal.index = null;
      this.editModal.id = null;
      this.editModal.room = null;
      this.editModal.cost = '';
      this.editModal.sale = '';
    },
    handleEditOptionModalOk() {
      const obj = Object.assign({}, this.editModal);
      if (obj.id !== null) {
        this.set(obj);
      } else {
        this.add(obj);
      }
    },

    removeOption() {
      const obj = Object.assign({}, this.editModal);

      if (obj.id !== null) {
        this.remove(obj);
      } else {
        this.selectedOptions.splice(obj.index, 1);
      }
      this.$bvModal.hide('modal-edit-hotel-option');
    },

    // CUSTOM OPTION MODAL
    openCustomOptionModal() {
      this.$bvModal.hide('modal-hotel-option-listings');
      this.$bvModal.show('modal-custom-hotel-option');
    },
    handleCustomOptionModalCancel() {
      this.addModal.activeStep = 1;
      this.addModal.room = {adult: '', child: '', child_2: ''};
      this.addModal.cost = '';
      this.addModal.sale = '';
      this.$bvModal.hide('modal-custom-hotel-option');
      this.$bvModal.show('modal-hotel-option-listings');
    },
    handleCustomOptionModalOk() {
      const obj = Object.assign({}, this.addModal);
      const sale = obj.sale;
      const cost = obj.cost;
      const room = obj.room;

      const postData = {
        cost,
        sale,
        adult: room.adult,
        child: room.child,
        child_2: room.child_2,
        pnr: this.pnr
      }
      ApiService.post('experience/calendar/event/hotel-expenses/add.req.php', postData)
      .then(({ data }) => {
        const newOption = {
          id: data.expenseId,
          room: room,
          cost: cost,
          sale: sale
        }
        this.selectedOptions.push(newOption);
        this.$bvModal.hide('modal-hotel-option-listings');
        this.$emit('notify', 'success', 'Başarılı! Değişiklikler kaydedildi.');
      })
      .catch(({ response }) => {
        this.$emit('notify', 'danger', response.data.errorMessage);
      });
    }
  }
}
</script>

<style scoped>
.option {
  cursor: initial;
  border-width: 2px;
}
.option.selectable {
  cursor: pointer;
}
.option.selectable.selected,
.option.selectable:hover {
  border-color: var(--dark);
  background-color: var(--light);
}
.option.selected {
  border-width: 2px;
}
.checkbox.checkbox-pill > span {
  border-radius: .35rem !important;
}
</style>