<template>
  <div>
    <div class="card card-custom my-5">
      <div class="card-body">
        <label class="font-weight-bolder text-dark">Ekstra Hizmetler</label>
        <div v-if="!error.status">
          <div class="d-flex justify-content-center min-h-100px" v-if="isLoading">
            <div class="spinner spinner-dark"></div>
          </div>
          <div v-else>
            <div class="d-flex align-items-center my-5 bg-white bg-hover-light border rounded px-5 py-3" v-for="(item, index) in extraServices" :key="index" @click="openEditModal(index)">
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <a href="javascript:;" class="font-weight-bold text-dark-75 font-size-md mb-1">{{ item.name.tr }}</a>
                <span class="text-muted">{{ item.description.tr }}</span>
              </div>
            </div>
            <button class="btn btn-sm btn-light font-weight-bold" @click="$bvModal.show('modal-add-extra-service')" v-if="!error.status">Hizmet ekle</button>
          </div>
          
        </div>
        
        <div v-else>
          <p> {{ error.message }} </p>
        </div>
      </div>
    </div>

    <!--Begin: ADD MODAL-->
    <b-modal id="modal-add-extra-service" ref="modal-add-extra-service" no-fade scrollable centered header-class="py-3" footer-class="py-3" @cancel="handleAddModalCancel" @hide="handleAddModalCancel" @ok="handleAddModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ addModal.activeStep }} ADIM/{{addModal.steps.length}}</label>
            <h5>{{ addModal.steps[addModal.activeStep - 1].title }}</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div v-if="addModal.activeStep === 1">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Türkçe</label>
            <input type="text" class="form-control form-control-lg" v-model="addModal.name.tr">
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">İngilizce</label>
            <input type="text" class="form-control form-control-lg" v-model="addModal.name.en">
          </div>
        </div>

        <div v-if="addModal.activeStep === 2">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Türkçe açıkla</label>
            <textarea
              class="form-control mb-2"
              rows="2"
              placeholder="Açıklamayı buraya yazın"
              :maxlength="summaryMaxLength"
              v-model="addModal.description.tr"
            ></textarea>
            <span class="text-muted pl-1">
              {{ addModal.description.tr.length }} / {{ summaryMaxLength }}
            </span>
          </div>

          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">İngilizce açıkla</label>
            <textarea
              class="form-control mb-2"
              rows="2"
              placeholder="Açıklamayı buraya yazın"
              :maxlength="summaryMaxLength"
              v-model="addModal.description.en"
            ></textarea>
            <span class="text-muted pl-1">
              {{ addModal.description.en.length }} / {{ summaryMaxLength }}
            </span>
          </div>
        </div>

        <div v-if="addModal.activeStep === 3">
          <div class="form-group">
            <div class="radio-inline">
              <label class="radio radio-dark font-size-lg font-weight-bold mb-6">
                <input type="radio" name="radioPriceType" value="1" v-model.number="addModal.priceType"/>
                <span></span>
                Kişi başı
              </label>
              <label class="radio radio-dark font-size-lg font-weight-bold mb-6">
                <input type="radio" name="radioPriceType" value="2" v-model.number="addModal.priceType"/>
                <span></span>
                Grup başı
              </label>
            </div>
          </div>

          <div class="row" v-if="addModal.priceType === 1">
            <div class="form-group col-xl-6" v-for="(item, index) in participants" :key="index">
              <label class="text-body font-weight-bold pl-1">{{ experienceConfig(`participant.${item.id}.tr`) }}</label>
              <div class="input-group">
                <div class="input-group-prepend"><span class="input-group-text">TRY</span></div>
                <input type="number" class="form-control form-control-lg" v-model="addModal[item.id]">
                <div class="input-group-append"><span class="input-group-text">.00</span></div>
              </div>
            </div>
          </div>

          <div class="form-group" v-else>
            <label class="text-body font-weight-bold pl-1">Grup ücreti</label>
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text">TRY</span></div>
              <input type="number" class="form-control form-control-lg" v-model="addModal.groupPrice">
              <div class="input-group-append"><span class="input-group-text">.00</span></div>
            </div>
          </div>
        </div>

        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
            v-if="addModal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="addModal.activeStep = addModal.activeStep - 1" 
            v-if="addModal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>
          
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="isAddModalSaveDisabled"
            v-if="addModal.activeStep == addModal.steps.length"
          >
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="isAddModalSaveDisabled"
            @click="addModal.activeStep = addModal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: ADD MODAL-->

    <!--Begin: ADD MODAL-->
    <b-modal id="modal-edit-extra-service" ref="modal-edit-extra-service" no-fade scrollable centered header-class="py-3" footer-class="py-3" @cancel="handleEditModalCancel" @hide="handleEditModalCancel" @ok="handleEditModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ editModal.activeStep }} ADIM/{{editModal.steps.length}}</label>
            <h5>{{ editModal.steps[editModal.activeStep - 1].title }}</h5>
          </div>
        </div>
        <button class="btn btn-sm btn-icon btn-light-danger" @click="removeExtraService">
          <i class="flaticon2-trash icon-md"></i>
        </button>
      </template>
      
      <div class="max-h-300px">
        <div v-if="editModal.activeStep === 1">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Türkçe</label>
            <input type="text" class="form-control form-control-lg" v-model="editModal.name.tr">
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">İngilizce</label>
            <input type="text" class="form-control form-control-lg" v-model="editModal.name.en">
          </div>
        </div>

        <div v-if="editModal.activeStep === 2">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Türkçe açıkla</label>
            <textarea
              class="form-control mb-2"
              rows="2"
              placeholder="Açıklamayı buraya yazın"
              :maxlength="summaryMaxLength"
              v-model="editModal.description.tr"
            ></textarea>
            <span class="text-muted pl-1">
              {{ editModal.description.tr.length }} / {{ summaryMaxLength }}
            </span>
          </div>

          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">İngilizce açıkla</label>
            <textarea
              class="form-control mb-2"
              rows="2"
              placeholder="Açıklamayı buraya yazın"
              :maxlength="summaryMaxLength"
              v-model="editModal.description.en"
            ></textarea>
            <span class="text-muted pl-1">
              {{ editModal.description.en.length }} / {{ summaryMaxLength }}
            </span>
          </div>
        </div>

        <div v-if="editModal.activeStep === 3">
          <div class="form-group">
            <div class="radio-inline">
              <label class="radio radio-dark font-size-lg font-weight-bold mb-6">
                <input type="radio" name="radioPriceType" value="1" v-model.number="editModal.priceType"/>
                <span></span>
                Kişi başı
              </label>
              <label class="radio radio-dark font-size-lg font-weight-bold mb-6">
                <input type="radio" name="radioPriceType" value="2" v-model.number="editModal.priceType"/>
                <span></span>
                Grup başı
              </label>
            </div>
          </div>

          <div class="row" v-if="editModal.priceType === 1">
            <div class="form-group col-xl-6" v-for="(item, index) in participants" :key="index">
              <label class="text-body font-weight-bold pl-1">{{ experienceConfig(`participant.${item.id}.tr`) }}</label>
              <div class="input-group">
                <div class="input-group-prepend"><span class="input-group-text">TRY</span></div>
                <input type="number" class="form-control form-control-lg" v-model="editModal[item.id]">
                <div class="input-group-append"><span class="input-group-text">.00</span></div>
              </div>
            </div>
          </div>

          <div class="form-group" v-else>
            <label class="text-body font-weight-bold pl-1">Grup ücreti</label>
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text">TRY</span></div>
              <input type="number" class="form-control form-control-lg" v-model="editModal.groupPrice">
              <div class="input-group-append"><span class="input-group-text">.00</span></div>
            </div>
          </div>
        </div>

        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
            v-if="editModal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="editModal.activeStep = editModal.activeStep - 1" 
            v-if="editModal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>
          
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="isEditModalSaveDisabled"
            v-if="editModal.activeStep == editModal.steps.length"
          >
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="isEditModalSaveDisabled"
            @click="editModal.activeStep = editModal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: ADD MODAL-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from '@/core/services/api.service';

export default {  
  props: ["experienceId", "pnr"],
  data() {
    return {
      extraServices: [],
      participants: [],
      summaryMaxLength: 120,
      isLoading: true,
      error: {
        status: false,
        message: ''
      },
      addModal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Hizmetin adını girin'
          },
          {
            number: 2,
            title: 'Hizmetin açıklamasını girin'
          },
          {
            number: 3,
            title: 'Üceti girin'
          }
        ],
        name: {
          tr: '',
          en: ''
        },
        description: {
          tr: '',
          en: ''
        },
        priceType: 1,
        groupPrice: 0,
        passenger: 0,
        elderly: 0,
        adult: 0,
        youth: 0,
        student: 0,
        child: 0,
        child_2: 0,
        infant: 0,
      },
      editModal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Hizmetin adı'
          },
          {
            number: 2,
            title: 'Hizmetin açıklaması'
          },
          {
            number: 3,
            title: 'Ücet bilgisi'
          }
        ],
        id: null,
        name: {
          tr: '',
          en: ''
        },
        description: {
          tr: '',
          en: ''
        },
        priceType: 1,
        groupPrice: 0,
        passenger: 0,
        elderly: 0,
        adult: 0,
        youth: 0,
        student: 0,
        child: 0,
        child_2: 0,
        infant: 0,
      },
    }
  },
  computed: {
    ...mapGetters(["experienceConfig"]),

    isAddModalSaveDisabled() {
      if (this.addModal.activeStep === 1) {
        if (this.addModal.name.tr.length === 0 || this.addModal.name.en.length === 0) {
          return true;
        }
      } else if (this.addModal.activeStep === 2) {
        if (this.addModal.description.tr.length === 0 || this.addModal.description.en.length === 0) {
          return true;
        }
      }
      return false;
    }
  },
  mounted() {
    this.get();
    this.getParticipants();
  },
   watch: {
    pnr() {
      this.get();
      this.getParticipants();
    }
  },
  methods: {
    get() {
      ApiService.get(`experience/calendar/event/extra-services/get.req.php?pnr=${this.pnr}`)
      .then(({ data }) => {
        this.extraServices = data.services;
        this.error.status = false;
        setTimeout(() => {
          this.isLoading = false;  
        }, 250);
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
        setTimeout(() => {
          this.isLoading = false;  
        }, 250);
      });
    },

    getParticipants() {
      ApiService.get(`experience/participant/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.participants = data.selectedParticipants;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    handleAddModalCancel() {
      this.addModal.activeStep = 1;
      this.addModal.name = {tr: '', en: ''};
      this.addModal.description = {tr: '', en: ''};
      this.addModal.priceType = 1;
      this.addModal.groupPrice = 0;
      this.addModal.passenger = 0;
      this.addModal.elderly = 0;
      this.addModal.adult = 0;
      this.addModal.youth = 0;
      this.addModal.student = 0;
      this.addModal.child = 0;
      this.addModal.child_2 = 0;
      this.addModal.infant = 0;
    },
    handleAddModalOk() {
      const obj = Object.assign({}, this.addModal);
      const postData = {
        pnr: this.pnr,
        priceType: obj.priceType,
        groupPrice: obj.groupPrice,
        name: JSON.stringify(obj.name),
        description: JSON.stringify(obj.description),
        passenger: obj.passenger,
        elderly: obj.elderly,
        adult: obj.adult,
        youth: obj.youth,
        student: obj.student,
        child: obj.child,
        child2: obj.child_2,
        infant: obj.infant
      }
      ApiService.post('experience/calendar/event/extra-services/add.req.php', postData)
      .then(() => {
        this.get();
        this.$emit('notify', 'success', 'Başarılı! Değişiklikler kaydedildi.');
      })
      .catch(({ response }) => {
        this.$emit('notify', 'danger', response.data.errorMessage);
      });
    },

    openEditModal(index) {
      const service = Object.assign({}, this.extraServices[index]);

      this.editModal.id = service.id;
      this.editModal.name = service.name;
      this.editModal.description = service.description;
      this.editModal.priceType = service.price.type;
      this.editModal.groupPrice = service.price.groupPrice;
      this.editModal.passenger = service.price.passenger;
      this.editModal.elderly = service.price.elderly;
      this.editModal.adult = service.price.adult;
      this.editModal.youth = service.price.youth;
      this.editModal.student = service.price.student;
      this.editModal.child = service.price.child;
      this.editModal.child_2 = service.price.child_2;
      this.editModal.infant = service.price.infant;

      this.$bvModal.show('modal-edit-extra-service');
    },
    handleEditModalCancel() {
      this.editModal.activeStep = 1;
      this.editModal.id = null;
      this.editModal.name = {tr: '', en: ''};
      this.editModal.description = {tr: '', en: ''};
      this.editModal.priceType = 1;
      this.editModal.groupPrice = 0;
      this.editModal.passenger = 0;
      this.editModal.elderly = 0;
      this.editModal.adult = 0;
      this.editModal.youth = 0;
      this.editModal.student = 0;
      this.editModal.child = 0;
      this.editModal.child_2 = 0;
      this.editModal.infant = 0;
    },
    handleEditModalOk() {
      const obj = Object.assign({}, this.editModal);
      const postData = {
        pnr: this.pnr,
        id: obj.id,
        priceType: obj.priceType,
        groupPrice: obj.groupPrice,
        name: JSON.stringify(obj.name),
        description: JSON.stringify(obj.description),
        passenger: obj.passenger,
        elderly: obj.elderly,
        adult: obj.adult,
        youth: obj.youth,
        student: obj.student,
        child: obj.child,
        child2: obj.child_2,
        infant: obj.infant
      }
      ApiService.post('experience/calendar/event/extra-services/set.req.php', postData)
      .then(() => {
        this.get();
        this.$emit('notify', 'success', 'Başarılı! Değişiklikler kaydedildi.');
      })
      .catch(({ response }) => {
        this.$emit('notify', 'danger', response.data.errorMessage);
      });
    },

    removeExtraService() {
      const obj = Object.assign({}, this.editModal);
      const id = obj.id;

      const postData = {
        pnr: this.pnr,
        id: id
      }
      ApiService.post('experience/calendar/event/extra-services/remove.req.php', postData)
      .then(() => {
        this.get();
        this.$emit('notify', 'success', 'Başarılı! Değişiklikler kaydedildi.');
      })
      .catch(({ response }) => {
        this.$emit('notify', 'danger', response.data.errorMessage);
      });

      this.$bvModal.hide('modal-edit-extra-service');
    }
  }
}
</script>

<style>

</style>