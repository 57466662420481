<template>
  <transition name="section" leave-active-class="hidden">
    <div class="notification-container d-flex justify-content-center">
      <div class="alert" :class="`alert-${state} bg-light-${state}`" role="alert">
        <span class="svg-icon svg-icon-lg pr-2" :class="`svg-icon-${state}`">
          <inline-svg :src="`media/svg/icons/${stateIcons[state]}`"/>
        </span>
        <span :class="`text-body`">{{ message }}</span>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ["state", "message"],
  data() {
    return {
      stateIcons: {
        success: 'Code/Done-circle.svg',
        danger: 'Code/Error-circle.svg',
        warning: 'Code/Warning-1-circle.svg'
      }
    }
  }
}
</script>

<style scoped>
.notification-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: -200px;
  z-index: 100;
  animation: slideUp .8s ease forwards;
}
.notification-container.hidden {
  animation: slideDown .8s ease forwards;
}
@keyframes slideUp {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-225px);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-225px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>