<template>
  <div>
    <DepartureTimes :pnr="pnr" :service-type="serviceType" :theme="theme" @notify="notify"/>
    <SolidityRatio :pnr="pnr" @notify="notify" @soldseat="soldSeat" v-if="serviceType === 1"/>
    <CutOff :pnr="pnr" @notify="notify"/>
    <FreeCancellation :pnr="pnr" @notify="notify"/>
    <ServiceSummary :pnr="pnr" @notify="notify"/>
  </div>
</template>

<script>
import ServiceSummary from "@/view/pages/experience/components/calendar/event_content/ServiceSummary";
import FreeCancellation from "@/view/pages/experience/components/calendar/event_content/FreeCancellation";
import CutOff from "@/view/pages/experience/components/calendar/event_content/CutOff";
import SolidityRatio from "@/view/pages/experience/components/calendar/event_content/SolidityRatio";
import DepartureTimes from "@/view/pages/experience/components/calendar/event_content/DepartureTimes";

export default {
  props: ["experienceId", "pnr", "serviceType", "theme"],
  components: {
    ServiceSummary,
    FreeCancellation,
    CutOff,
    SolidityRatio,
    DepartureTimes
  },
  methods: {
    notify(state, message) {
      this.$emit('notify', state, message);
    },
    soldSeat(soldSeat) {
      this.$emit('soldseat', soldSeat)
    }
  }
}
</script>

<style>

</style>